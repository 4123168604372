@import 'arrows';
@import '../utilities/spacing';
@import '../bifrost/typography-vars';

$bf-input-height: 40px;
$bf-input-height-small: 32px;
$bf-icon-size: 16px;
$bf-prepended-box-min-width: 54px;
$bf-prepended-box-min-width-sm: 47px;

$bf-icon-vertical-space: 12px;
$bf-icon-vertical-space-sm: 8px;
$bf-icon-and-border-space: 15px;
$bf-icon-and-border-space-sm: 10px;

$bf-transition-duration: 300ms;

.cs-form {
  position: relative;
}

.cs-label {
  @extend .cs-title-8, .cs-c-shade-100, .cs-capitalize-first-letter;

  line-height: $bf-text-line-height-1;
  margin-bottom: 5px;
  width: 100%;
  display: block;
}

.cs-input {
  @extend .cs-font-family-basic;

  background-color: $bf-shade-0;
  font-size: $bf-text-font-size-1;
  line-height: $bf-text-line-height-1;
  width: 100%;
  border-radius: $radius;
  border: 1px solid $bf-shade-15;
  color: $bf-shade-100;
  font-weight: $bf-font-weight-text;
  height: $bf-input-height;
  box-shadow: none;
  padding: 0 15px;
  transition: border-color 150ms ease;

  &:disabled,
  &[disabled] {
    border-color: $bf-shade-8;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:disabled::placeholder {
    color: $bf-shade-25;
  }

  &:disabled::-ms-input-placeholder {
    color: $bf-shade-25;
  }

  &:hover {
    border-color: $bf-shade-25;
  }

  &:active,
  &:focus {
    outline: 0;
    border-color: $bf-shade-40;
  }

  &::placeholder,
  ::-ms-input-placeholder {
    color: $bf-shade-40;
    opacity: 1;
  }

  &-sm {
    @extend .cs-input;

    font-size: $bf-text-font-size-2;
    padding: 8px 10px;
    height: $bf-input-height-small;
  }

  &-with-icon {
    @extend .cs-input;

    &-left {
      padding-left: calc(#{$bf-icon-and-border-space} * 3);

      &-sm {
        left: $bf-icon-and-border-space-sm;
        padding-left: calc(
          #{$bf-icon-and-border-space-sm} * 2 + #{$bf-icon-size} - #{$bf-icon-size} / 4
        );
        font-weight: $bf-font-weight-text;
        font-size: $bf-text-font-size-2;
        height: $bf-input-height-small;
      }
    }

    &-right {
      padding-right: calc(#{$bf-icon-and-border-space} * 3);

      &-sm {
        right: $bf-icon-and-border-space-sm;
        padding-right: calc(
          #{$bf-icon-and-border-space-sm} * 2 + #{$bf-icon-size} - #{$bf-icon-size} / 4
        );
        font-weight: $bf-font-weight-text;
        font-size: $bf-text-font-size-2;
        height: $bf-input-height-small;
      }
    }
  }

  &-icon {
    &-left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 0;
      position: absolute;
      left: $bf-icon-and-border-space;
      bottom: $bf-icon-vertical-space;
      width: $bf-icon-size !important;
      height: $bf-icon-size !important;

      &-sm {
        left: $bf-icon-and-border-space-sm;
        bottom: $bf-icon-vertical-space-sm;
      }
    }

    &-right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 0;
      position: absolute;
      right: $bf-icon-and-border-space;
      bottom: $bf-icon-vertical-space;
      width: $bf-icon-size !important;
      height: $bf-icon-size !important;

      &-sm {
        right: $bf-icon-and-border-space-sm;
        bottom: $bf-icon-vertical-space-sm;
      }
    }
  }

  &-prepended-box-wrapper {
    display: flex;
    flex-direction: row;
  }

  &-prepended-box {
    @extend .cs-text-2, .cs-c-shade-60;

    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: $bf-shade-6;
    min-width: $bf-prepended-box-min-width;
    height: $bf-input-height;
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
    border-top: 1px solid $bf-shade-6;
    border-bottom: 1px solid $bf-shade-6;
    border-left: 1px solid $bf-shade-6;

    &-sm {
      @extend .cs-input-prepended-box, .cs-text-3;

      min-width: $bf-prepended-box-min-width-sm;
      height: $bf-input-height-small;
      padding: 8px 10px;

      img,
      mat-icon,
      i,
      link {
        bottom: $bf-icon-vertical-space-sm !important;
        left: 12px !important;
      }
    }

    img,
    mat-icon,
    i,
    link {
      position: absolute;
      bottom: $bf-icon-vertical-space;
      left: calc((#{$bf-prepended-box-min-width} / 2) - (#{$bf-icon-size} / 2));
    }
  }

  &-with-prepended-box {
    @extend .cs-input;

    border-left: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    &-sm {
      @extend .cs-input-with-prepended-box;

      padding: 8px 10px;
      height: $bf-input-height-small;
      font-size: $bf-text-font-size-2;
      min-width: 40px;
    }
  }
}

.cs-textarea {
  @extend .cs-input;

  resize: none;
  padding: 10px 15px;
}

.cs-control {
  &-wrapper {
    position: relative;

    label {
      @extend .cs-label;
    }
  }

  &-error {
    @extend .cs-text-2, .cs-c-error;

    padding-top: 3px;
    position: absolute;
    animation: dropIn $bf-transition-duration ease-in, fadeIn $bf-transition-duration ease-in;
  }
}

.cs-select-arrow {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 10 6' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M.706,0A.665.665,0,0,0,.07.464a.746.746,0,0,0,.2.8L4.321,4.989.27,8.716A.737.737,0,0,0,.009,9.4a.7.7,0,0,0,.45.563.63.63,0,0,0,.672-.173L5.768,5.529a.746.746,0,0,0,0-1.082L1.13.179A.637.637,0,0,0,.706,0Z' transform='translate(10) rotate(90)'/></svg>"); // TODO: This way of importing makes sure there are no conflicts within Storybook, Scout and other apps
  background-repeat: no-repeat;
  background-position: calc(100% - 13px);
}

.cs-radio-option {
  border: 1px solid $bf-shade-10;
  border-radius: $radius;
  display: flex;
  padding: 15px;
  cursor: pointer;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $bf-shade-2;
  }

  &.active {
    background: $bf-shade-4;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    background-color: $bf-shade-10;
    border: 4px solid $bf-shade-10;
    border-radius: 100%;
    position: relative;
    margin: auto;
    padding: 2px;

    &:checked {
      background-color: $bf-shade-0;
      border: 4px solid $bf-brand-primary-100;
    }
  }

  input[type='radio']:focus {
    outline: none;
    cursor: pointer;
  }

  &-content {
    padding-left: 10px;
    width: 100%;
  }
}

input.cs-input.ng-invalid.ng-touched,
input.cs-input-with-icon.ng-invalid.ng-touched,
input.cs-input-with-icon-sm.ng-invalid.ng-touched,
textarea.cs-textarea.ng-invalid.ng-touched {
  border: 1px solid $bf-error-30;
  transition: border-color $bf-transition-duration ease, color $bf-transition-duration ease;
}

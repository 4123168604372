@mixin stroke-color-utility-class-generator($stroke-colors, $stroke-opacities) {
  @each $color in $stroke-colors {
    @each $opacity in $stroke-opacities {
      .cs-stroke-#{$color}-#{$opacity} {
        $color-name: #{$color}-#{$opacity};
        stroke: map-get($bifrost-colors, $color-name);

        * {
          stroke: map-get($bifrost-colors, $color-name);
        }
      }

      .cs-stroke-i-#{$color}-#{$opacity} {
        $color-name: #{$color}-#{$opacity};
        stroke: map-get($bifrost-colors, $color-name);

        * {
          stroke: map-get($bifrost-colors, $color-name) !important;
        }
      }
    }
  }
}

@include stroke-color-utility-class-generator($bifrost-colors-list, $bifrost-opacities-list);
@include stroke-color-utility-class-generator(
  $bifrost-shade-colors-list,
  $bifrost-shade-opacities-list
);

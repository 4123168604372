.cs-uploader {
  @extend .cs-c-shade-40;

  border-radius: $radius;
  border: 2px dashed $bf-shade-40;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  cursor: pointer;
  transition: border-color 150ms ease, color 150ms ease;

  &:hover {
    @extend .cs-c-shade-80;

    border-color: $bf-shade-85;
  }

  &-icon {
    filter: grayscale(1);
    width: 25px;
    height: 25px;
  }

  &-image {
    width: 110px;
    height: 110px;
    border-radius: $radius;
    border: 1px solid $bf-shade-15;
    margin-right: 20px;
    position: relative;

    &-loading {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      background-color: $bf-shade-10;
      animation: bg-shade-10-out-in-out 5s infinite;
    }

    &-background {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &-remove-icon {
      visibility: hidden;
      cursor: pointer;
      width: 25px;
      height: 25px;
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index: 1;
    }

    &:hover > .cs-uploader-image-remove-icon {
      visibility: visible;
    }
  }
}

$toggle-active-color: $bf-brand-primary-100;
$slider-box-color: $bf-shade-10;
$slider-color: $bf-shade-0;

$toggle-box-height: 20px;
$toggle-box-width: 34px;
$toggle-radius: 5px;
$slider-radius: 3px;
$slider-width: 14px;
$space-between-slider-and-box-border: 3px;

$transition-animation-duration: 300ms;

// This toggle is to be used when you bind the value of the toggle with a formControl
// Examples: E-commerce, Googly My Business, Google Search
.cs-toggle-switchbox {
  position: relative;
  display: inline-block;
  width: $toggle-box-width;
  height: $toggle-box-height;

  input {
    display: none;
  }

  .cs-toggle-slider:before {
    height: $slider-width;
    width: $slider-width;
    bottom: $space-between-slider-and-box-border;
    left: $space-between-slider-and-box-border;
  }

  &.active {
    background: $toggle-active-color;

    &:after {
      transform: translateX(-5%);
    }
  }
}

.cs-toggle-slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  width: $toggle-box-width;
  border-radius: $toggle-radius;
  background-color: $slider-box-color;
  -webkit-transition: $transition-animation-duration;
  transition: $transition-animation-duration;

  &-static {
    background-color: $toggle-active-color;
  }
}

.cs-toggle-slider:before {
  position: absolute;
  content: '';
  left: $space-between-slider-and-box-border;
  bottom: $space-between-slider-and-box-border;
  background-color: $slider-color;
  -webkit-transition: $transition-animation-duration;
  transition: $transition-animation-duration;
  border-radius: $slider-radius;
}

input:checked + .cs-toggle-slider {
  background-color: $toggle-active-color;
}

input:focus + .cs-toggle-slider {
  box-shadow: 0 0 1px $toggle-active-color;
}

input:checked + .cs-toggle-slider:before {
  -webkit-transform: translateX(95%);
  -ms-transform: translateX(95%);
  transform: translateX(95%);
}

// This toggle is to be used when no forms/form controls are used or connected to what you want to do,
// Or when you handle UI of the toggle based on a key present in a BehaviorSubject state (isActive/active)
// Do not use this one - UI should not be connected directly to state, use a form to mediate between them
// Examples: Templates, Domains, Payment Plans
.cs-toggle {
  display: inline-block;
  border-radius: $toggle-radius;
  position: relative;
  background: $slider-box-color;
  width: $toggle-box-width;
  height: $toggle-box-height;
  transition: background $transition-animation-duration ease;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    border-radius: $slider-radius;
    left: 50%;
    transform: translateX(-95%);
    top: $space-between-slider-and-box-border;
    width: $slider-width;
    height: $slider-width;
    background: $slider-color;
    display: block;
    transition: transform $transition-animation-duration ease;
  }

  &.active {
    background: $toggle-active-color;

    &:after {
      transform: translateX(-5%);
    }
  }
}

// Bifrost variables - a new design presented August, 2021 for the first time

// Use these colors present here to style color-related CSS
// if the style you need is not present in Cobiro Styles itself

// Brand colors
// Primary
$bf-brand-primary-100: #0063ff; // Base
$bf-brand-primary-90: #1972ff;
$bf-brand-primary-80: #3282ff;
$bf-brand-primary-70: #4c91fe;
$bf-brand-primary-60: #65a1ff;
$bf-brand-primary-50: #7fb1ff;
$bf-brand-primary-40: #99c0ff;
$bf-brand-primary-30: #b2d0ff;
$bf-brand-primary-20: #ccdffe;
$bf-brand-primary-10: #e5efff;

// Secondary
$bf-brand-secondary-100: #9d00ff; // Base
$bf-brand-secondary-90: #a619ff;
$bf-brand-secondary-80: #b032ff;
$bf-brand-secondary-70: #ba4cfe;
$bf-brand-secondary-60: #c465ff;
$bf-brand-secondary-50: #cd7fff;
$bf-brand-secondary-40: #d799ff;
$bf-brand-secondary-30: #e1b2ff;
$bf-brand-secondary-20: #ebccfe;
$bf-brand-secondary-10: #f5e5ff;

// Tertiary
$bf-brand-tertiary-100: #fd0fd0; // Base
$bf-brand-tertiary-90: #fd27d4;
$bf-brand-tertiary-80: #fd3ed9;
$bf-brand-tertiary-70: #fd56de;
$bf-brand-tertiary-60: #fd6fe2;
$bf-brand-tertiary-50: #fe86e7;
$bf-brand-tertiary-40: #fe9fec;
$bf-brand-tertiary-30: #feb7f0;
$bf-brand-tertiary-20: #fecff5;
$bf-brand-tertiary-10: #fee7fa;

// Quaternary
$bf-brand-quaternary-100: #00f5ff; // Base
$bf-brand-quaternary-90: #19f6ff;
$bf-brand-quaternary-80: #32f7ff;
$bf-brand-quaternary-70: #4cf8fe;
$bf-brand-quaternary-60: #65f9ff;
$bf-brand-quaternary-50: #7ffaff;
$bf-brand-quaternary-40: #99fbff;
$bf-brand-quaternary-30: #b2fcff;
$bf-brand-quaternary-20: #ccfcfe;
$bf-brand-quaternary-10: #e5feff;

// Shades
$bf-shade-100: #111111; // Base, #000 with 7% opacity
$bf-shade-95: #1c1c1c;
$bf-shade-90: #282828;
$bf-shade-85: #343434;
$bf-shade-80: #404040;
$bf-shade-75: #4c4c4c;
$bf-shade-70: #585858;
$bf-shade-65: #646464;
$bf-shade-60: #707070;
$bf-shade-55: #7c7c7c;
$bf-shade-50: #888888;
$bf-shade-45: #939393;
$bf-shade-40: #9f9f9f;
$bf-shade-35: #ababab;
$bf-shade-30: #b7b7b7; // Equivalent to #bababa in custom component styles
$bf-shade-25: #c3c3c3;
$bf-shade-20: #cfcfcf;
$bf-shade-15: #dbdbdb;
$bf-shade-10: #e7e7e7; // Equivalent of    rgba(0, 0, 0, 0.09)
$bf-shade-8: #ebebeb;
$bf-shade-6: #f0f0f0;
$bf-shade-4: #f5f5f5;
$bf-shade-2: #fafafa;
$bf-shade-0: #ffffff; // White, separate - any color with 0 opacity is white

// Additional colors

// Success
$bf-success-100: #163e1b;
$bf-success-90: #215d28;
$bf-success-80: #2c7c36;
$bf-success-70: #379b44;
$bf-success-60: #43ba51;
$bf-success-50: #61c66d;
$bf-success-40: #80d18a;
$bf-success-30: #9fdca7;
$bf-success-20: #bfe8c4; // Success - base
$bf-success-10: #def3e1; // Success-20 at 50% opacity

// Warning
$bf-warning-100: #553800;
$bf-warning-90: #7f5500;
$bf-warning-80: #aa7100;
$bf-warning-70: #d48d00;
$bf-warning-60: #feaa00;
$bf-warning-50: #feb82b;
$bf-warning-40: #ffc655;
$bf-warning-30: #ffd480;
$bf-warning-20: #ffe3ab; // Warning - base
$bf-warning-10: #fef1d5; // Warning-20 at 50% opacity

// Error
$bf-error-100: #580b01;
$bf-error-90: #841102;
$bf-error-80: #b01702;
$bf-error-70: #dc1c03;
$bf-error-60: #fb2c11;
$bf-error-50: #fb533d;
$bf-error-40: #fc7a69;
$bf-error-30: #fda195;
$bf-error-20: #fec9c2; // Error - base
$bf-error-10: #fee3e0; // Error-20 at 50% opacity

$bifrost-colors: (
  primary-100: $bf-brand-primary-100,
  primary-90: $bf-brand-primary-90,
  primary-80: $bf-brand-primary-80,
  primary-70: $bf-brand-primary-70,
  primary-60: $bf-brand-primary-60,
  primary-50: $bf-brand-primary-50,
  primary-40: $bf-brand-primary-40,
  primary-30: $bf-brand-primary-30,
  primary-20: $bf-brand-primary-20,
  primary-10: $bf-brand-primary-10,
  secondary-100: $bf-brand-secondary-100,
  secondary-90: $bf-brand-secondary-90,
  secondary-80: $bf-brand-secondary-80,
  secondary-70: $bf-brand-secondary-70,
  secondary-60: $bf-brand-secondary-60,
  secondary-50: $bf-brand-secondary-50,
  secondary-40: $bf-brand-secondary-40,
  secondary-30: $bf-brand-secondary-30,
  secondary-20: $bf-brand-secondary-20,
  secondary-10: $bf-brand-secondary-10,
  tertiary-100: $bf-brand-tertiary-100,
  tertiary-90: $bf-brand-tertiary-90,
  tertiary-80: $bf-brand-tertiary-80,
  tertiary-70: $bf-brand-tertiary-70,
  tertiary-60: $bf-brand-tertiary-60,
  tertiary-50: $bf-brand-tertiary-50,
  tertiary-40: $bf-brand-tertiary-40,
  tertiary-30: $bf-brand-tertiary-30,
  tertiary-20: $bf-brand-tertiary-20,
  tertiary-10: $bf-brand-tertiary-10,
  quaternary-100: $bf-brand-quaternary-100,
  quaternary-90: $bf-brand-quaternary-90,
  quaternary-80: $bf-brand-quaternary-80,
  quaternary-70: $bf-brand-quaternary-70,
  quaternary-60: $bf-brand-quaternary-60,
  quaternary-50: $bf-brand-quaternary-50,
  quaternary-40: $bf-brand-quaternary-40,
  quaternary-30: $bf-brand-quaternary-30,
  quaternary-20: $bf-brand-quaternary-20,
  quaternary-10: $bf-brand-quaternary-10,
  shade-100: $bf-shade-100,
  shade-95: $bf-shade-95,
  shade-90: $bf-shade-90,
  shade-85: $bf-shade-85,
  shade-80: $bf-shade-80,
  shade-75: $bf-shade-75,
  shade-70: $bf-shade-70,
  shade-65: $bf-shade-65,
  shade-60: $bf-shade-60,
  shade-55: $bf-shade-55,
  shade-50: $bf-shade-50,
  shade-45: $bf-shade-45,
  shade-40: $bf-shade-40,
  shade-35: $bf-shade-35,
  shade-30: $bf-shade-30,
  shade-25: $bf-shade-25,
  shade-20: $bf-shade-20,
  shade-15: $bf-shade-15,
  shade-10: $bf-shade-10,
  shade-8: $bf-shade-8,
  shade-6: $bf-shade-6,
  shade-4: $bf-shade-4,
  shade-2: $bf-shade-2,
  shade-0: $bf-shade-0,
  success-100: $bf-success-100,
  success-90: $bf-success-90,
  success-80: $bf-success-80,
  success-70: $bf-success-70,
  success-60: $bf-success-60,
  success-50: $bf-success-50,
  success-40: $bf-success-40,
  success-30: $bf-success-30,
  success-20: $bf-success-20,
  success-10: $bf-success-10,
  warning-100: $bf-warning-100,
  warning-90: $bf-warning-90,
  warning-80: $bf-warning-80,
  warning-70: $bf-warning-70,
  warning-60: $bf-warning-60,
  warning-50: $bf-warning-50,
  warning-40: $bf-warning-40,
  warning-30: $bf-warning-30,
  warning-20: $bf-warning-20,
  warning-10: $bf-warning-10,
  error-100: $bf-error-100,
  error-90: $bf-error-90,
  error-80: $bf-error-80,
  error-70: $bf-error-70,
  error-60: $bf-error-60,
  error-50: $bf-error-50,
  error-40: $bf-error-40,
  error-30: $bf-error-30,
  error-20: $bf-error-20,
  error-10: $bf-error-10,
);

$bifrost-colors-list: (primary, secondary, tertiary, quaternary, success, warning, error);
$bifrost-opacities-list: (10, 20, 30, 40, 50, 60, 70, 80, 90, 100);

$bifrost-shade-colors-list: (shade);
$bifrost-shade-opacities-list: (
  100,
  95,
  90,
  85,
  80,
  75,
  70,
  65,
  60,
  55,
  50,
  45,
  40,
  35,
  30,
  25,
  20,
  15,
  10,
  8,
  6,
  4,
  2,
  0
);

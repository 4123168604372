@charset "utf-8";

//Import external styles
@import './styles/external';

// Global layout
@import './styles/layout';
@import './styles/defaults';
@import './styles/material-theming';

.root-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@import 'projects/cobiro-styles/default-theme/default-theme';
@import 'projects/cobiro-styles/styles'; // TODO: If we remove this file, make sure to correct workspace.json Storybook asset/styles loading path to point directly to cobiro-styles

$c-google-title: #1a0dab;
$c-selected-background: #002f53; // Used in 1-2 places
$c-selected-border-dark: #003c6b; // Used in 2 places
$c-text-field: #002d4f;

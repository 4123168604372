// TODO: problem with breakpoint small, snackbar overlay becomes 100% height of the page
// Investigate more with design team, do it better on Friday
.mat-snack-bar-handset {
  max-height: 40px !important;
}

.cs-mat-alert {
  box-shadow: 0 0 32px $bf-shade-10 !important;
  font-family: $f-family-basic;

  .mat-button {
    padding: 0 8px;
  }

  .mat-button-wrapper {
    text-indent: -9999px;

    &:before {
      content: '\2715';
    }
  }
}

.cs-mat-alert-success {
  background-color: $bf-success-10 !important;
  color: $bf-success-100 !important;

  .mat-button-wrapper {
    color: $bf-success-100 !important;
  }
}

.cs-mat-alert-flat {
  background-color: $bf-shade-8 !important;
  color: $bf-shade-65 !important;

  .mat-button-wrapper {
    color: $bf-shade-65 !important;
  }
}

.cs-mat-alert-primary {
  background-color: $bf-brand-primary-10 !important;
  color: $bf-brand-primary-100 !important;

  .mat-button-wrapper {
    color: $bf-brand-primary-100 !important;
  }
}

.cs-mat-alert-warning {
  background-color: $bf-warning-10 !important;
  color: $bf-warning-100 !important;

  .mat-button-wrapper {
    color: $bf-warning-100 !important;
  }
}

.cs-mat-alert-error {
  background-color: $bf-error-10 !important;
  color: $bf-error-100 !important;

  .mat-button-wrapper {
    color: $bf-error-100 !important;
  }
}

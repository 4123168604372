.content-loading {
  overflow: hidden;
  position: relative;
  $standard-height: 12px;

  /**
    Overriding with !important is required here.
    There is no other known way to override components class styles.
    Components class selectors have higher specificity over the style.css by the order of precedence.
   */
  h1,
  h2,
  h3,
  h4,
  h5,
  span,
  b,
  label,
  a,
  p,
  input,
  textarea,
  ng-select {
    color: transparent !important;
    border: none !important;
  }

  label {
    &.input-checkbox {
      display: none;
    }
  }

  img {
    background: $bf-shade-10;
  }

  .cs-badge,
  mat-tab-group,
  img,
  button,
  mat-icon {
    display: none;
  }

  cbr-google-map {
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 100%;
      background: $bf-shade-10;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  h4 {
    position: relative;
    height: $bf-title-font-size-6;

    &:before {
      content: '';
      display: block;
      height: $bf-title-font-size-6;
      width: 60%;
      background: $bf-shade-10;
      position: absolute;
      top: 0;
    }
  }

  h2,
  h3 {
    height: $bf-title-font-size-4;

    &:before {
      content: '';
      display: block;
      height: $bf-title-font-size-4;
      width: 60%;
      background: $bf-shade-10;
      top: 0;
    }
  }

  h1 {
    height: $bf-title-font-size-3;
    width: 100%;

    &:before {
      content: '';
      display: block;
      height: $bf-title-font-size-3;
      width: 100%;
      background: $bf-shade-10;
      top: 0;
    }
  }

  b {
    position: relative;

    &:before {
      content: '';
      display: block;
      height: $standard-height;
      width: 100%;
      background: $bf-shade-10;
      top: 0;
      margin: 2px 0;
    }
  }

  label,
  a,
  p {
    height: 42px;
    position: relative;

    &:before {
      content: '';
      display: block;
      height: $standard-height;
      width: 100%;
      background: $bf-shade-10;
      top: 0;
      margin: 2px 0;
    }

    &:after {
      content: '';
      display: block;
      height: $standard-height;
      width: 80%;
      background: $bf-shade-10;
      top: 18px;
      margin: 2px 0;
      position: absolute;
    }

    &.single {
      height: $standard-height;
      margin: 2px 0;

      &:after {
        display: none;
      }
    }
  }

  span {
    color: transparent;
    height: 42px;
    position: relative;

    &:before {
      content: '';
      display: block;
      height: $standard-height;
      width: 100%;
      background: $bf-shade-10;
      top: 0;
      margin: 2px 0;
    }
  }

  label {
    height: $standard-height;
    width: 100px;

    &:after {
      display: none;
    }
  }

  &:before {
    @extend .cs-z-index-10;

    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    width: 500%;
    margin-left: -250%;
    animation: contentLoadingKeyframes 800ms linear infinite;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 46%,
        rgba(255, 255, 255, 0.35) 50%,
        rgba(255, 255, 255, 0) 54%
      )
      50% 50%;
  }

  input,
  textarea,
  ng-select {
    background-color: $bf-shade-10;
    // Hack to remove the placeholder
    font-size: 0;
  }

  ng-select {
    .ng-value,
    .ng-placeholder {
      opacity: 0;
    }

    .ng-arrow-wrapper {
      opacity: 0;
    }
  }

  .arrows {
    display: none;
  }

  ng-select.ng-select .ng-select-container {
    border: none;
  }

  .field input {
    border: none;
  }
}

@mixin bg-color-utility-class-generator($bg-colors, $bg-opacities) {
  @each $color in $bg-colors {
    @each $opacity in $bg-opacities {
      $color-name: #{$color}-#{$opacity};
      .cs-bg-#{$color}-#{$opacity} {
        background-color: map-get($bifrost-colors, $color-name) !important;
      }
      .cs-bg-hover-#{$color}-#{$opacity}:hover {
        background-color: map-get($bifrost-colors, $color-name) !important;
      }
    }
  }
}

@include bg-color-utility-class-generator($bifrost-colors-list, $bifrost-opacities-list);
@include bg-color-utility-class-generator(
  $bifrost-shade-colors-list,
  $bifrost-shade-opacities-list
);

.cs-cobiro-linear-gradient {
  background: linear-gradient(
    0.25turn,
    $bf-brand-quaternary-100,
    $bf-brand-primary-100,
    $bf-brand-secondary-100,
    $bf-brand-tertiary-100
  );

  &-reverse {
    background: linear-gradient(
      0.25turn,
      $bf-brand-tertiary-100,
      $bf-brand-secondary-100,
      $bf-brand-primary-100,
      $bf-brand-quaternary-100
    );
  }
}

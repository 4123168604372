// TODO: Used in b-rex main page, and copied in other places - clean-up later to avoid conflicts
.dropdown-backdrop + .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
  position: absolute !important;
}

.cs-mat-menu {
  min-width: 120px !important;
  margin: 5px 0;
  border: 1px solid $bf-shade-10;
  box-shadow: 0 3px 6px #1111111a !important;
  min-height: 40px !important;

  &-trigger {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    width: 32px;
    height: 32px;
    outline: none;
    cursor: pointer;
    background: transparent;

    &:hover {
      background: $bf-shade-6;
    }
  }

  &-backdrop + * .cdk-overlay-pane {
    margin: 0 !important;
  }

  .mat-mdc-menu-content {
    padding: 10px !important;

    .mat-mdc-menu-item {
      height: 36px;
      line-height: 36px;
      border-radius: 5px;
      padding: 0 10px;
      font-family: $f-family-basic !important;
    }

    // TODO: (ml910) reconsider
    .mat-mdc-menu-item.cs-mat-menu-item-adjustable-height {
      height: auto;
      padding: 10px;
    }
  }

  .active {
    background-color: $bf-shade-6;
    color: $bf-shade-100;
  }
}

@import '../keyframes/visibility';

.cs-loading-bar {
  width: 100%;
  height: 10px;
  border-radius: $radius;
  position: relative;
  background-color: $bf-shade-20;

  &::after {
    content: ' ';
    width: 100%;
    height: 10px;
    border-radius: $radius;
    position: absolute;
    background: linear-gradient(to right, $bf-shade-100, $bf-shade-100) repeat-y;
    animation: expandAndContract 2s ease-in-out infinite;
  }
}

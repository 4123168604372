.cs-tabs-list {
  display: flex;
  width: 100%;
  list-style-type: none;
  padding: ($grid-gutter-width * 0.5) $grid-gutter-width 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.cs-tab {
  display: flex;
  flex: 1;
  border-radius: $radius;

  &.active {
    background-color: $bf-shade-0;
    box-shadow: 0 1px 2px #00000029;
  }

  &-wrapper {
    display: flex;
    padding: 5px;
    background-color: $bf-shade-4;
    border-radius: $radius;
  }

  &-button {
    padding: 6px 10px;
    background-color: transparent;
    min-width: 60px;
    width: 100%;
    flex: 1;
    cursor: pointer;
    border: none;

    &:hover:not([disabled]) {
      .tab-label {
        color: $bf-shade-100;
      }
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }

  &-label {
    font-size: $bf-text-font-size-2;
    font-weight: $bf-font-weight-title;
    color: $bf-shade-25;

    &.active {
      color: $bf-shade-100;
    }
  }
}

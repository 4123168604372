.cs-badge {
  padding: 5px 10px;
  letter-spacing: 0;
  border-radius: 3px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  font-size: 10px;

  &-primary {
    background-color: $bf-brand-primary-10;
    color: $bf-brand-primary-100;
  }

  &-secondary {
    background-color: $bf-brand-secondary-10;
    color: $bf-brand-secondary-100;
  }

  &-tertiary {
    background-color: $bf-brand-tertiary-10;
    color: $bf-brand-tertiary-100;
  }

  &-quaternary {
    background-color: $bf-brand-quaternary-10;
    color: $bf-brand-quaternary-100;
  }

  &-flat {
    background-color: $bf-shade-8;
    color: $bf-shade-65;
  }

  &-success {
    background-color: $bf-success-10;
    color: $bf-success-100;
  }

  &-warning {
    color: $bf-warning-100;
    background-color: $bf-warning-10;
  }

  &-error {
    background-color: $bf-error-10;
    color: $bf-error-100;
  }

  &-sm {
    height: 16px;
    padding: 3px 9px;
  }
}

$border-radii: (0, 5, 10, 15, 100);
@each $radius in $border-radii {
  .br-#{$radius} {
    border-radius: #{$radius}px;
  }
}

$borders: (
  b: (
    border,
  ),
  bl: (
    border-left,
  ),
  br: (
    border-right,
  ),
  bt: (
    border-top,
  ),
  bb: (
    border-bottom,
  ),
  bh: (
    border-left,
    border-right,
  ),
  bv: (
    border-top,
    border-bottom,
  ),
);

$border-widths: (1, 2);

@mixin border-width-utility-class-generator($borders, $border-widths) {
  @each $border-name, $border-props in $borders {
    @each $width in $border-widths {
      .cs-#{$border-name}-#{$width} {
        @each $prop in $border-props {
          #{$prop}: #{$width}px solid map-get($bifrost-colors, 'shade-100');
        }
      }
    }
  }
}

@mixin border-color-utility-class-generator($border-colors, $border-opacities) {
  @each $color in $border-colors {
    @each $opacity in $border-opacities {
      .cs-b-#{$color}-#{$opacity} {
        $color-name: #{$color}-#{$opacity};
        border-color: map-get($bifrost-colors, $color-name) !important;
      }
    }
  }
}

@include border-width-utility-class-generator($borders, $border-widths);
@include border-color-utility-class-generator($bifrost-colors-list, $bifrost-opacities-list);
@include border-color-utility-class-generator(
  $bifrost-shade-colors-list,
  $bifrost-shade-opacities-list
);

.cs-b-dashed {
  border-style: dashed;
}

.cs-br-50-pc {
  border-radius: 50%;
}

.cs-bb-none-last-item:last-of-type {
  border-bottom: none !important;
}

.cs-bb-none {
  border-bottom: none !important;
}

.cs-b-none {
  border: none !important;
}

.cs-btn {
  $animation: 300ms ease;
  $horizontal-padding: 15px;
  $standard-font-size: 14px;
  $icon-animation-size: 12px;
  $icon-text-margin: 10px;
  $spinner-width: 2px;
  $border-size: 1px;

  min-height: 40px;
  min-width: 40px;
  font-weight: 600;
  border-radius: 5px;
  font-size: $standard-font-size;
  line-height: $standard-font-size * 1.5;
  padding: 10px $horizontal-padding;
  font-family: $f-family-basic;

  border: none;
  display: flex;
  cursor: pointer;
  user-select: none;
  position: relative;
  align-items: center;
  text-decoration: none;
  word-break: break-word;
  box-sizing: border-box;
  justify-content: center;
  transition: background-color $animation, border-color $animation, color $animation;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &[disabled],
  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  .cs-btn-icon {
    margin-right: $icon-text-margin;
    align-self: center;
    width: $icon-animation-size;
    height: $icon-animation-size;
    display: flex;
  }

  &.is-loading {
    $animation-duration: 500ms;

    pointer-events: none;
    opacity: 0.5;
    transition: opacity ($animation-duration * 2), color $animation-duration ease-out;

    &::before {
      $b-size: 2px;

      height: $icon-animation-size;
      width: $icon-animation-size;

      content: '';
      display: flex;
      border-radius: 100%;
      box-sizing: border-box;
      margin-right: $icon-text-margin;
      border: $b-size solid $bf-shade-20;
      background-color: transparent !important;
      animation: rotateFully $animation-duration infinite linear,
        moveTextRightAndOpacify $animation-duration ease-out;
      border-color: transparent transparent $bf-shade-0 $bf-shade-0 !important;
      left: calc(50% - #{($icon-animation-size + $b-size) * 0.5});
      top: calc(50% - #{($icon-animation-size + $b-size) * 0.5});

      @keyframes moveTextRightAndOpacify {
        from {
          margin-right: 0;
          opacity: 0;
        }
        33% {
          margin-right: 5px;
          opacity: 0.33;
        }
        to {
          margin-right: 10px;
          opacity: 1;
        }
      }
    }

    .cs-btn-icon {
      display: none;
    }
  }

  &.cs-btn-sm {
    $responsive-size: $standard-font-size - 2px;

    min-height: 24px;
    min-width: 32px;
    max-height: 100%;
    font-size: $responsive-size;
    padding: 7px $horizontal-padding - 5px;

    .cs-btn-icon {
      width: $responsive-size;
      height: $responsive-size;
      margin-right: 5px !important;
    }
  }

  &.cs-btn-primary {
    $border-shadow-color: $bf-brand-primary-80;
    $content-color: $bf-shade-100;
    $content-color-disabled: $bf-shade-25;

    color: $bf-shade-0;
    background-color: $bf-brand-primary-100;

    &:hover {
      background-color: $bf-brand-primary-80;
    }

    &.active {
      background-color: $bf-brand-primary-90;
    }

    &-squared {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      background: $bf-brand-primary-100;
      justify-content: center;
      border: $border-size solid $bf-brand-primary-100;
      border-radius: 5px;
      padding: 7px;
      cursor: pointer;
      color: $bf-shade-0;

      &:hover {
        background: $bf-brand-primary-80;
        border: $border-size solid $bf-brand-primary-80;
      }

      &:active {
        opacity: 1;
      }

      &.active {
        background-color: $bf-shade-8;
      }
    }

    &.is-loading {
      color: $bf-brand-primary-10;

      &::before {
        border: $spinner-width solid $bf-brand-primary-20;
        border-color: transparent transparent $bf-brand-primary-10 $bf-brand-primary-10 !important;
      }
    }

    &[disabled] {
      color: $bf-brand-primary-10;
      background-color: $bf-brand-primary-50;

      &.is-loading {
        &::before {
          border: $spinner-width solid $bf-brand-primary-20;
          border-color: transparent transparent $bf-brand-primary-10 $bf-brand-primary-10 !important;
        }
      }
    }

    .cs-btn-icon {
      fill: $bf-shade-0;
      color: $bf-shade-0;

      &[disabled] {
        fill: $bf-brand-primary-10;
        color: $bf-brand-primary-10;
      }
    }
  }

  &.cs-btn-error {
    $border-shadow-color: $bf-error-70;
    $content-color: $bf-shade-0;
    $content-color-disabled: $bf-error-10;

    $content-color: $bf-shade-0;
    $disabled-content-color: $bf-error-10;

    background-color: $bf-error-80;
    color: $content-color;

    &:hover {
      background-color: $bf-error-70; // $bf-error-75 would look better
    }

    &.active {
      background-color: $bf-error-70;
    }

    &-squared {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      background-color: $bf-error-80;
      justify-content: center;
      border: $border-size solid $bf-error-80;
      border-radius: 5px;
      padding: 7px;
      cursor: pointer;
      color: $bf-shade-0;

      &:hover {
        background-color: $bf-error-70;
        border: $border-size solid $bf-error-70;
      }

      &:active {
        opacity: 1;
      }

      &.active {
        background-color: $bf-shade-8;
      }

      &.is-loading {
        color: $bf-error-10;

        &::before {
          border: $spinner-width solid $bf-error-20;
          border-color: transparent transparent $bf-error-10 $bf-error-10 !important;
        }
      }

      &[disabled] {
        color: $bf-error-10;
        background-color: $bf-error-30;

        &.is-loading::before {
          border: $spinner-width solid $bf-error-20;
          border-color: transparent transparent $bf-error-10 $bf-error-10 !important;
        }
      }

      .cs-btn-icon {
        fill: $content-color;
        color: $content-color;

        &[disabled] {
          fill: $bf-error-10;
          color: $bf-error-10;
        }
      }
    }
  }

  &.cs-btn-flat {
    $border-shadow-color: $bf-shade-15;
    $content-color: $bf-shade-60;
    $content-color-disabled: $bf-shade-25;

    $content-color: $bf-shade-60;
    $content-color-hover: $bf-shade-100;
    $content-color-disabled: $bf-shade-25;

    color: $content-color-hover;
    background-color: $bf-shade-8;

    &:hover {
      color: $content-color-hover;
      background-color: $bf-shade-15;

      .cs-btn-icon {
        fill: $content-color-hover;
        color: $content-color-hover;
      }
    }

    &.active {
      background-color: $bf-shade-15;
    }

    &-squared {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      background: $bf-shade-8;
      justify-content: center;
      border: $border-size solid $border-shadow-color;
      border-radius: 5px;
      padding: 7px;
      cursor: pointer;
      color: $content-color-hover;

      &:hover {
        background: $border-shadow-color;
      }

      &:active {
        opacity: 1;
      }

      &.active {
        background-color: $bf-shade-8;
      }
    }

    &.is-loading {
      color: $content-color;

      &::before {
        border: $spinner-width solid $bf-error-20;
        border-color: transparent transparent $content-color $content-color !important;
      }
    }

    &[disabled] {
      background-color: $bf-shade-6;
      color: $content-color-disabled;

      &.is-loading::before {
        border: $spinner-width solid $content-color-disabled;
        border-color: transparent transparent $content-color-disabled $content-color-disabled !important;
      }
    }

    .cs-btn-icon {
      fill: $content-color;
      color: $content-color;

      &[disabled] {
        fill: $content-color-disabled;
        color: $content-color-disabled;
      }
    }

    &.is-loading {
      &::before {
        border: $spinner-width solid $content-color;
        border-color: transparent transparent $content-color $content-color !important;
      }
    }
  }

  &.cs-btn-stroked {
    $border-shadow-color: $bf-shade-15;
    $content-color: $bf-shade-100;
    $content-color-disabled: $bf-shade-25;

    color: $content-color;
    background-color: $bf-shade-0;
    box-shadow: 1px 1px 2px rgba($border-shadow-color, 0.2);
    border: $border-size solid $border-shadow-color;

    &:hover {
      background-color: $bf-shade-6;
    }

    &.active {
      background-color: $bf-shade-6;
      border-color: $bf-shade-40;
    }

    &.is-loading {
      &::before {
        border: $spinner-width solid $content-color;
        border-color: transparent transparent $content-color $content-color !important;
      }
    }

    &[disabled] {
      background-color: $bf-shade-0;
      color: $content-color-disabled;

      &.is-loading::before {
        border: $spinner-width solid $content-color-disabled;
        border-color: transparent transparent $content-color-disabled $content-color-disabled !important;
      }
    }

    .cs-btn-icon {
      fill: $content-color;
      color: $content-color;

      &[disabled] {
        fill: $content-color-disabled;
        color: $content-color-disabled;
      }
    }

    &-dashed {
      border: 2px dashed $border-shadow-color;
    }

    &-squared {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      background: transparent;
      justify-content: center;
      border: $border-size solid $border-shadow-color;
      border-radius: 5px;
      padding: 7px;
      cursor: pointer;
      opacity: 0.7;

      &:hover {
        background: $bf-shade-10;
      }

      &:active {
        opacity: 1;
      }

      &.active {
        background-color: $bf-shade-8;
      }
    }
  }

  &.cs-btn-ghost {
    $content-color: $bf-shade-60;
    $content-color-hover: $bf-shade-100;
    $content-color-disabled: $bf-shade-25;

    background-color: transparent;
    color: $content-color;

    &:hover {
      color: $content-color-hover;
      background-color: $bf-shade-4;

      .cs-btn-icon {
        fill: $content-color-hover;
        color: $content-color-hover;
      }
    }

    &.active {
      background-color: $bf-shade-4;
    }

    &.is-loading {
      color: $content-color-disabled;

      &::before {
        border: $spinner-width solid $content-color-disabled;
        border-color: transparent transparent $content-color-disabled $content-color-disabled !important;
      }
    }

    &[disabled] {
      background-color: transparent;
      color: $content-color-disabled;

      &.is-loading::before {
        border: $spinner-width solid $content-color-disabled;
        border-color: transparent transparent $content-color-disabled $content-color-disabled !important;
      }
    }

    .cs-btn-icon {
      fill: $content-color;
      color: $content-color;

      &[disabled] {
        fill: $content-color-disabled;
        color: $content-color-disabled;
      }
    }

    &-squared {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      background: transparent;
      justify-content: center;
      border-radius: 5px;
      border: none;
      padding: 7px;
      cursor: pointer;
      opacity: 0.7;

      &:hover {
        background: $bf-shade-10;
      }

      &:active {
        opacity: 1;
      }

      &.active {
        background-color: $bf-shade-8;
      }

      mat-icon,
      img,
      i {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.cs-btn-gradient {
    border: none;
    border-radius: 5px;
    background: transparent;
    color: $bf-shade-80;
    font-size: $standard-font-size - 2px;

    &:hover {
      color: $bf-shade-100;
      background: $c-gradient-1; // TODO: No equivalent in Bifrost - missing palette for yellow
      background: linear-gradient(
        107deg,
        $c-gradient-1 0%,
        $c-gradient-2 100%
      ); // TODO: No equivalent in Bifrost - missing palette for yellow and missing step for warning palette
    }
  }

  &.cs-btn-social {
    display: flex;
    font-size: 14px;
    align-items: center;
    font-weight: $bf-font-weight-text;
    padding: 11px 24px;

    .social-logo {
      margin-right: 20px;
    }

    &-google {
      background: $bf-shade-0;
      border: 1px solid $bf-shade-15;

      &.is-loading:after {
        border-color: transparent transparent $bf-shade-100 $bf-shade-100 !important;
        opacity: 0.54;
      }
    }

    &-facebook {
      background-color: $bf-brand-primary-90;
      border: 1px solid transparent;
    }
  }
}

// TODO: DO NOT USE IT - use .cs-btn .cs-btn-ghost-squared instead
.cs-btn-hover {
  display: flex;
  align-items: center;
  background: transparent;
  justify-content: center;
  border-radius: 5px;
  border: none;
  padding: 7px;
  cursor: pointer;

  &:hover {
    background: $bf-shade-10;
  }

  &:active {
    opacity: 0.7;
  }
}

.cs-btn-dashed {
  $content-color: $bf-shade-60;

  color: $content-color;
  background: $bf-shade-0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed $bf-shade-15;
  transition: background 200ms ease, border-color 200ms ease;
  border-radius: 5px;
  padding: 10px;
  height: 40px;
  cursor: pointer;

  &:hover {
    background-color: $bf-shade-4;
    border-color: $bf-shade-30;
  }

  &.is-loading {
    &::before {
      border-color: transparent transparent $content-color $content-color !important;
    }
  }
}

.cs-mat-chip-list-sm .mat-chip-list-wrapper {
  $inner-input-height-sm: 32px;
  $min-total-input-height: 48px;
  $vertical-break: 8px;
  $horizontal-break: 10px;

  min-height: 35px;
  margin: 0;

  .mat-chip-input {
    min-height: $inner-input-height-sm;
    margin: $vertical-break 0 0 5px;

    &:before {
      margin-bottom: $vertical-break;
    }
  }

  .mat-chip {
    margin: $vertical-break 5px 0 0;
    font-size: $bf-text-font-size-2;
    font-weight: 600;
    border-radius: 5px;

    .mat-chip-remove {
      opacity: 1;
      font-weight: normal;
      margin-bottom: 2px;
    }
  }

  .mat-standard-chip {
    height: $inner-input-height-sm;
    padding-top: $vertical-break;
  }
}

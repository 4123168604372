.mat-mdc-autocomplete-panel {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f !important;
  background: white;
  color: #000000de;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-mdc-option {
  &:hover {
    background: $bf-shade-6;
  }
}

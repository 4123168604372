@import './../bifrost/typography-vars';
@import './../utilities/animations';
@import './../components/arrows';

$mat-datepicker-popup-height: 354px;

$cs-mat-calendar-regular-font-size: 12px;
$cs-mat-calendar-selected-date-background-color: $bf-brand-primary-100;
$cs-mat-calendar-empty-field-hover-background-color: $bf-brand-primary-20;
$cs-mat-calendar-range-background-color: $bf-brand-primary-10;

.mat-datepicker-popup {
  margin-top: 5px;
  height: $mat-datepicker-popup-height;

  @media (min-width: 0) {
    left: unset;
    right: 16px;
  }

  .mat-datepicker-content {
    border-radius: $radius;
    border: 1px solid $bf-shade-10;
    box-shadow: 0 3px 6px #0000001a; // TODO: Check out box shadow values

    .mat-calendar-table-header-divider:after {
      background: $bf-shade-8;
    }

    .mat-calendar-table-header tr th {
      padding-bottom: 5px;
    }
  }
}

.cs-mat-datepicker-wrapper {
  .mat-datepicker-toggle-default-icon {
    display: none;
  }

  .mat-datepicker-toggle,
  .mat-datepicker-content .mat-calendar-previous-button,
  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.cs-mat-date-range-input {
  .mat-date-range-input-container {
    position: absolute;
    top: 10px !important;
  }
}

.cs-mat-datepicker-toggle {
  height: 40px;
  z-index: 1;

  &-no-label {
    top: 10px !important;
  }

  &-with-label {
    top: 23px !important;
  }
}

.cs-mat-datepicker-toggle-icon {
  position: absolute;
  right: 10px;
  width: 20px !important;
  height: 20px !important;

  &-no-label {
    top: 10px;
  }

  &-with-label {
    top: 32px;
  }
}

.cs-mat-date-range-picker {
  @extend .cs-font-family-basic;

  .mat-button {
    font-size: $cs-mat-calendar-regular-font-size;
    line-height: 18px;
    font-weight: 600;
    text-transform: lowercase;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  .mat-calendar-period-button {
    padding: 0 24px 0 16px;
  }

  .mat-button-wrapper span {
    vertical-align: unset;
  }

  .mat-calendar-arrow {
    border-left: 0;
    border-right: 0;

    &:after {
      position: absolute;
      top: 15px;
      right: 15px;

      border-color: $bf-shade-60;
      border-style: solid;
      border-width: 0 2px 2px 0;
      content: '';
      display: inline-block;
      padding: 2px;
      vertical-align: middle;
      transform: rotate(45deg);
    }
  }

  .mat-calendar-arrow.mat-calendar-invert {
    border-left: 0;
    border-right: 0;

    &:after {
      position: absolute;
      top: -5px;
      right: 4px;

      border-color: $bf-shade-60;
      border-style: solid;
      border-width: 0 2px 2px 0;
      content: '';
      display: inline-block;
      padding: 2px;
      vertical-align: middle;
      transform: rotate(45deg);
    }
  }

  .mat-calendar-abbr {
    font-size: $cs-mat-calendar-regular-font-size;
  }

  .mat-calendar-body-cell-content.mat-focus-indicator {
    font-size: $cs-mat-calendar-regular-font-size;

    &.mat-calendar-body-today.mat-calendar-body-selected {
      box-shadow: unset;
    }

    &.mat-calendar-body-selected {
      background-color: $cs-mat-calendar-selected-date-background-color;
    }
  }

  .mat-calendar-previous-button:after,
  .mat-calendar-next-button:after {
    margin: 17px;
  }

  .mat-calendar-previous-button:after {
    transform: translateX(1px) rotate(-45deg);
  }

  .mat-calendar-next-button:after {
    transform: translateX(-1px) rotate(45deg);
  }

  .mat-calendar-body-in-range:before {
    background-color: $cs-mat-calendar-range-background-color;
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    border-color: $cs-mat-calendar-selected-date-background-color;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ),
  .cdk-keyboard-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ),
  .cdk-program-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    @extend .cs-transition-regular-ease-out;

    background-color: $cs-mat-calendar-empty-field-hover-background-color;
  }
}

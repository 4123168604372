$indexes: (0, 1, 2, 3, 4, 5, 10, 100000);

$positions: (static, relative, absolute, sticky, fixed);
$position-percentage-value: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100
);

@mixin z-index($index) {
  $z-index: #{$index};

  .cs-z-index-#{$index} {
    z-index: $z-index;
  }
}

@each $index in $indexes {
  @include z-index($index);
}

@mixin responsive-position($type, $breakpoint) {
  .cs-position-#{$type}-#{$breakpoint} {
    @include respond-above($breakpoint) {
      position: $type;
    }
  }
}

@each $breakpoint-name, $breakpoint-value in $grid-breakpoints {
  @each $type in $positions {
    @include responsive-position($type, $breakpoint-name);
  }
}

@mixin responsive-top-pc($value, $breakpoint) {
  .cs-top-#{$value}-pc-#{$breakpoint} {
    @include respond-above($breakpoint) {
      top: #{$value}#{'%'};
    }
  }
}

@mixin responsive-bottom-pc($value, $breakpoint) {
  .cs-bottom-#{$value}-pc-#{$breakpoint} {
    @include respond-above($breakpoint) {
      bottom: #{$value}#{'%'};
    }
  }
}

@mixin responsive-left-pc($value, $breakpoint) {
  .cs-left-#{$value}-pc-#{$breakpoint} {
    @include respond-above($breakpoint) {
      left: #{$value}#{'%'};
    }
  }
}

@mixin responsive-right-pc($value, $breakpoint) {
  .cs-right-#{$value}-pc-#{$breakpoint} {
    @include respond-above($breakpoint) {
      right: #{$value}#{'%'};
    }
  }
}

@each $breakpoint-name, $breakpoint-value in $grid-breakpoints {
  @each $value in $position-percentage-value {
    @include responsive-top-pc($value, $breakpoint-name);
    @include responsive-bottom-pc($value, $breakpoint-name);
    @include responsive-left-pc($value, $breakpoint-name);
    @include responsive-right-pc($value, $breakpoint-name);
  }
}

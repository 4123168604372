.table {
  @extend .cs-c-shade-60;

  width: 100%;
  border-collapse: collapse;

  thead {
    th {
      @extend .cs-text-1;

      padding: 16px 0;
      border-top-width: 0;
      border-bottom: 1px solid $bf-shade-10;
    }
  }

  tr {
    border-collapse: collapse;
  }

  td {
    border-bottom: 1px solid $bf-shade-10;
    padding: 30px 0;
  }
}

.cell {
  border-width: 0;
  padding: 5px;
}

.cell-dark-grey {
  @extend .cs-c-shade-60;
}

.last-cell:last-of-type {
  text-align: right;
}

.cs-table {
  @extend .cs-text-2, .cs-c-shade-60;

  width: 100%;
  border-collapse: collapse;
  text-align: left;

  th {
    @extend .cs-text-2;

    margin-bottom: 15px;
    border-top-width: 0;
    border-bottom: 1px solid $bf-shade-10;
    padding: 15px 10px 15px 0;
  }

  tr {
    border-collapse: collapse;
  }

  td {
    border-bottom-width: 0;
    padding: 20px 10px 20px 0;
  }

  tfoot {
    td {
      @extend .cs-title-6, .cs-c-shade-100;
      text-transform: uppercase;
      border-bottom-width: 0;
    }
  }

  &.cs-table-dashed {
    td {
      border-bottom: 1px solid $bf-shade-10;
    }

    tfoot {
      td {
        border-bottom-width: 0;
      }
    }
  }

  .cs-action-cell {
    width: 64px;
  }

  &.cs-table-boxed {
    th:first-of-type,
    td:first-of-type {
      padding-left: 30px;
    }

    th:last-of-type,
    td:last-of-type {
      padding-right: 30px;
    }
  }
}

@import '../utilities/animations';

.cs-mat-select {
  position: absolute;
  top: 30px;
  width: 100%;
  padding: 10px !important;

  &.cs-mat-select-sm {
    top: 25px;

    .mat-mdc-select-trigger {
      height: 1.125em !important;
    }
  }

  &.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
    color: $bf-shade-100 !important;
  }

  .mat-mdc-option.mat-selected:not(.mat-option-multiple) {
    border-radius: 5px;
  }

  .cs-mat-option {
    height: auto !important;
    padding: 5px;
    border-radius: 5px;

    &.mat-active * {
      color: $bf-shade-100 !important;
    }
  }

  mat-option {
    font-family: $f-family-basic;

    &.mat-mdc-option:hover:not(.mat-option-disabled),
    &.mat-mdc-option:focus:not(.mat-option-disabled) {
      background-color: $bf-shade-4 !important;
      border-radius: $radius;
    }
  }

  mat-form-field {
    .mat-mdc-form-field-flex {
      padding: 5px;
    }

    .mat-form-field-underline,
    .mat-form-field-ripple {
      background-color: $bf-shade-25 !important;
      color: $bf-shade-25 !important;
    }

    .mat-form-field-empty.mat-form-field-label,
    .mat-focused .mat-form-field-label {
      font-family: $f-family-basic;
      color: $bf-shade-25 !important;
    }

    .mat-input-element {
      caret-color: $bf-shade-25;
    }
  }
}

.mat-mdc-select-trigger {
  height: 2.125em !important;

  .mat-mdc-select-value {
    vertical-align: middle;
  }
}

.mat-mdc-form-field-bottom-align {
  height: 0 !important;
}

// Bifrost fonts, introduced in October, 2021 for the first time

/*
// TITLES, HEADERS
*/
.cs-title-1 {
  font-size: $bf-title-font-size-1 !important; // 3rem
  line-height: $bf-title-line-height-1 !important; // 3,625rem // ~120% font-size, lower than default
}

.cs-title-2 {
  font-size: $bf-title-font-size-2 !important; // 2,5rem
  line-height: $bf-title-line-height-2 !important; // 3rem // ~120% font-size, lower than default
}

.cs-title-3 {
  font-size: $bf-title-font-size-3 !important; // 2rem
  line-height: $bf-title-line-height-3 !important; // 2.625rem // ~130% font-size, lower than default
}

.cs-title-4 {
  font-size: $bf-title-font-size-4 !important; // 1.5rem
  line-height: $bf-title-line-height-4 !important; // 2rem // ~130% font-size, lower than default
}

.cs-title-5 {
  font-size: $bf-title-font-size-5 !important; // 1.125rem
  line-height: $bf-title-line-height-5 !important; // 1.625rem // ~150% font size, lower than default
}

.cs-title-6 {
  font-size: $bf-title-font-size-6 !important; // 1rem
  line-height: $bf-title-line-height-6 !important; // 1.5rem //  150% font size, same as default
}

.cs-title-7 {
  font-size: $bf-title-font-size-7 !important; // 0.875rem
  line-height: $bf-title-line-height-7 !important; // 1.325rem // 150% font size, same as default
}

.cs-title-8 {
  font-size: $bf-title-font-size-8 !important; // 0.75rem
  line-height: $bf-title-line-height-8 !important; // 1.125rem // 150% font weight, same as default
}

.cs-title-9 {
  font-size: $bf-title-font-size-9 !important; // 0.625rem
  line-height: $bf-title-line-height-9 !important; // 1rem // 160% font weight, same as default
}

/*
// BODY TEXT
*/
.cs-text-1 {
  font-size: $bf-text-font-size-1 !important; // 0.875rem
  line-height: $bf-text-line-height-1 !important; // 1.325rem // 150% font size, same as default
}

.cs-text-2 {
  font-size: $bf-text-font-size-2 !important; // 0.75rem
  line-height: $bf-text-line-height-2 !important; // 1.125rem // 150% font weight, same as default
}

.cs-text-3 {
  font-size: $bf-text-font-size-3 !important; // 0.625rem
  line-height: $bf-text-line-height-3 !important; // 1rem // 160% font weight, same as default
}

// Sets a default color for each font, but feel free to override it with classes below if you need to (cs-c-xxx)
.cs-title-1,
.cs-title-2,
.cs-title-3,
.cs-title-4,
.cs-title-5,
.cs-title-6,
.cs-title-7,
.cs-title-8,
.cs-title-9 {
  font-weight: $bf-font-weight-title !important;
  color: $bf-shade-100;
}

.cs-text-1,
.cs-text-2,
.cs-text-3 {
  font-weight: $bf-font-weight-text !important;
  color: $bf-shade-60;
}

.cs-title-1,
.cs-title-2,
.cs-title-3,
.cs-title-4,
.cs-title-5,
.cs-title-6,
.cs-title-7,
.cs-title-8,
.cs-title-9,
.cs-text-1,
.cs-text-2,
.cs-text-3 {
  font-family: $f-family-basic !important;
}

/*
// FONT COLORS
*/

// SHADE
.cs-c-shade-100 {
  color: $bf-shade-100 !important;

  &.cs-c-hover:hover {
    color: $bf-shade-70 !important;
  }
}

.cs-c-shade-80 {
  color: $bf-shade-80 !important;

  &.cs-c-hover:hover {
    color: $bf-shade-60 !important;
  }
}

.cs-c-shade-60 {
  color: $bf-shade-60 !important;

  &.cs-c-hover:hover {
    color: $bf-shade-40 !important;
  }
}

.cs-c-shade-40 {
  color: $bf-shade-40 !important;

  &.cs-c-hover:hover {
    color: $bf-shade-30 !important;
  }
}

.cs-c-shade-0 {
  color: $bf-shade-0 !important;

  &.cs-c-hover:hover {
    color: $bf-shade-10 !important;
  }
}

// PRIMARY
.cs-c-primary {
  color: $bf-brand-primary-100 !important;

  &.cs-c-hover:hover {
    color: $bf-brand-primary-80 !important;
  }
}

// SECONDARY
.cs-c-secondary {
  color: $bf-brand-secondary-100 !important;

  &.cs-c-hover:hover {
    color: $bf-brand-secondary-70 !important;
  }
}

// TERTIARY
.cs-c-tertiary {
  color: $bf-brand-tertiary-100 !important;

  &.cs-c-hover:hover {
    color: $bf-brand-tertiary-70 !important;
  }
}

// QUATERNARY
.cs-c-quaternary {
  color: $bf-brand-quaternary-100 !important;

  &.cs-c-hover:hover {
    color: $bf-brand-quaternary-60 !important;
  }
}

// SUCCESS
.cs-c-success {
  color: $bf-success-70 !important;

  &.cs-c-hover:hover {
    color: $bf-success-60 !important;
  }
}

// WARNING
.cs-c-warning {
  color: $bf-warning-70 !important;

  &.cs-c-hover:hover {
    color: $bf-warning-60 !important;
  }
}

// ERROR
.cs-c-error {
  color: $bf-error-70 !important;

  &.cs-c-hover:hover {
    color: $bf-error-60 !important;
  }
}

// LINK
.cs-link {
  @extend .cs-font-family-basic, .cs-c-primary;

  font-size: inherit !important;
  font-weight: 400 !important;
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
    cursor: pointer !important;
  }
}

// CUSTOM CLASSES
// These classes are most useful when dealing with Angular Material styling that you want to overwrite
.cs-font-family-basic {
  font-family: $f-family-basic !important;
}

.text-transform-none {
  text-transform: none !important;
}

.text-transform-lowercase {
  text-transform: lowercase;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.cs-white-space-pre-line {
  white-space: pre-line;
}

.cs-white-space-nowrap {
  white-space: nowrap;
}

.cs-capitalize-first-letter:first-letter {
  text-transform: capitalize;
}

@mixin box-shadow-utility-class-generator($colors, $opacities) {
  @each $color in $colors {
    @each $opacity in $opacities {
      .cs-shadow-#{$color}-#{$opacity} {
        $color-name: #{$color}-#{$opacity};
        box-shadow: 0 3px 20px map-get($bifrost-colors, $color-name);
      }
    }
  }
}

@include box-shadow-utility-class-generator($bifrost-colors-list, $bifrost-opacities-list);
@include box-shadow-utility-class-generator(
  $bifrost-shade-colors-list,
  $bifrost-shade-opacities-list
);

.cs-shadow-none {
  box-shadow: none !important;
}

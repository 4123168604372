.cs-terminal {
  background: $bf-shade-100;
  color: $bf-shade-6;
  border-radius: $radius;
  padding: 20px;
}

.cs-terminal-line {
  display: block;
}

.cs-terminal-loader {
  animation: blink 1s steps(5, start) infinite;
}

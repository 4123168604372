@import 'mixins';

.cs-scroll {
  overflow-y: auto;
  @include scrollbar();
}

.cs-scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.mat-mdc-select-panel {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f !important;
  background: white;
  color: #000000de;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-mdc-option {
  &:hover {
    background: $bf-shade-6;
  }
}
.cs-dropdown {
  @extend .cs-text-1, .cs-c-shade-60;

  line-height: 24px !important;
  display: block !important;

  .mdc-text-field {
    padding: 0;
  }

  .mdc-line-ripple::before,
  .mdc-line-ripple::after {
    border: none !important;
  }

  .mat-mdc-form-field-wrapper {
    padding-bottom: 0;
  }

  .ng-animating {
    visibility: hidden;
  }

  .mat-mdc-form-field-flex {
    border-radius: $radius !important;
    border: 1px solid $bf-shade-10;
    padding: 6px 10px !important;
    background-color: $bf-shade-0;
  }

  .mat-mdc-form-field-underline {
    display: none !important;
  }

  .mat-mdc-form-field-infix {
    border-top: 0;
    padding: 0;
    margin: auto;
  }
  &.ng-invalid.ng-touched .mat-mdc-text-field-wrapper {
    border: 1px solid #fda195;
    transition: border-color 0.3s ease, color 0.3s ease;
    border-radius: var(--mdc-shape-small, 6px);
  }

  .mat-mdc-select-arrow-wrapper {
    transform: none !important;
  }

  .mat-mdc-select-value {
    font-family: inherit !important;
    color: inherit !important;
  }

  .mat-mdc-select-arrow {
    svg {
      display: none;
    }
    position: relative;
    border-width: 0;
    margin: 0 8px;

    &:after,
    &:before {
      top: 0;
      left: 0;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-top-color: $bf-shade-0;
      border-width: 4px;
      margin-left: -4px;
    }

    &:before {
      border-color: transparent;
      border-top-color: $bf-shade-45;
      border-width: 5px;
      margin-left: -5px;
    }
  }

  .mat-mdc-select-trigger {
    vertical-align: middle;
  }
}

.cs-dropdown-options {
  margin-top: 40px;
  margin-left: 6px;

  .mat-mdc-option {
    font-family: $f-family-basic;
    padding: 4px 10px !important;
  }

  .mat-option-text {
    display: flex;
    align-items: center;
    color: $bf-shade-80;
    font-size: $bf-text-font-size-2;
  }

  .mat-selected {
    .mat-option-text {
      @extend .cs-c-primary;
    }
  }

  .mat-mdc-option.mat-selected:not(.mat-option-multiple) {
    background: $bf-shade-2;
  }

  .mat-mdc-option:hover:not(.mat-option-disabled) {
    @extend .cs-c-shade-100;
    background: $bf-shade-2;

    .mat-option-text {
      @extend .cs-c-shade-100;
    }
  }
}

.cs-select-trigger {
  @extend .cs-c-shade-100;

  display: flex;
  align-items: center;
}

.cs-mat-tooltip.mat-mdc-tooltip .mdc-tooltip__surface {
  @extend .cs-text-2;

  border-radius: 5px;
  background-color: $bf-shade-80;
  box-shadow: 2px 3px 6px rgba($bf-shade-100, 0.3);
  border: 1px solid $bf-shade-80;
  white-space: pre-line;
  word-break: break-word;
  padding: 20px;
  color: $bf-shade-0;
}

@import 'mixins';

$sizes: (
  xs: 12px,
  sm: 16px,
  md: 24px,
  md-plus: 36px,
  lg: 48px,
  xl: 64px,
  xxl: 80px,
);

@each $sizeName, $sizeValue in $sizes {
  .cs-size-#{$sizeName} {
    width: $sizeValue !important;
    height: $sizeValue !important;
    font-size: $sizeValue - 2px;
  }
}

@mixin generate-width($size, $breakpoint) {
  $pixel-size: #{$size}px;

  .cs-width-#{$size}-#{$breakpoint} {
    @include respond-above($breakpoint) {
      width: $pixel-size;
    }
  }
}

@mixin generate-min-width($size, $breakpoint) {
  $pixel-size: #{$size}px;

  .cs-min-width-#{$size}-#{$breakpoint} {
    @include respond-above($breakpoint) {
      min-width: $pixel-size;
    }
  }
}

@mixin generate-max-width($size, $breakpoint) {
  $pixel-size: #{$size}px;

  .cs-max-width-#{$size}-#{$breakpoint} {
    @include respond-above($breakpoint) {
      max-width: $pixel-size;
    }
  }
}

@mixin generate-height($size, $breakpoint) {
  $pixel-size: #{$size}px;

  .cs-height-#{$size}-#{$breakpoint} {
    @include respond-above($breakpoint) {
      height: $pixel-size;
    }
  }
}

@mixin generate-min-height($size, $breakpoint) {
  $pixel-size: #{$size}px;

  .cs-min-height-#{$size}-#{$breakpoint} {
    @include respond-above($breakpoint) {
      min-height: $pixel-size;
    }
  }
}

@mixin generate-max-height($size, $breakpoint) {
  $pixel-size: #{$size}px;

  .cs-max-height-#{$size}-#{$breakpoint} {
    @include respond-above($breakpoint) {
      max-height: $pixel-size;
    }
  }
}

@mixin generate-line-height($size) {
  $pixel-size: #{$size}px;

  .cs-line-height-#{$size} {
    line-height: $pixel-size;
  }
}

$size-properties: ('height', 'min-height', 'max-height', 'width', 'min-width', 'max-width');
$size-values: ('inherit', 'revert', 'unset', 'auto', 'min-content', 'max-content', '100', '100vh');
@mixin generate-size-property($properties, $values, $breakpoint) {
  @each $property in $properties {
    @each $value in $values {
      .cs-#{$property}-#{$value}-#{$breakpoint} {
        @include respond-above($breakpoint) {
          #{$property}: #{$value};
        }
      }
    }
  }
}

// TODO: Consider naming convention for percentages size styling
@mixin generate-size-percentage($properties, $breakpoint, $percentages) {
  @each $property in $properties {
    @each $percentage in $percentages {
      @include respond-above($breakpoint) {
        .cs-#{$property}-#{$percentage}-pc-#{$breakpoint} {
          #{$property}: #{$percentage}#{'%'};
        }
      }
    }
  }
}

$available-sizes: (
  0,
  5,
  10,
  16,
  20,
  25,
  30,
  32,
  40,
  50,
  60,
  80,
  100,
  120,
  150,
  160,
  180,
  200,
  210,
  220,
  240,
  250,
  290,
  300,
  310,
  360,
  440,
  480,
  500,
  625,
  700,
  850,
  960,
  1000,
  1060,
  1100,
  1180,
  1240
);

$available-percentages: (100, 90, 80, 75, 70, 60, 50, 40, 30, 25, 20, 10);

@each $breakpoint-name, $breakpoint-value in $grid-breakpoints {
  @each $size in $available-sizes {
    @include generate-width($size, $breakpoint-name);
  }

  @each $size in $available-sizes {
    @include generate-min-width($size, $breakpoint-name);
  }

  @each $size in $available-sizes {
    @include generate-max-width($size, $breakpoint-name);
  }

  @each $size in $available-sizes {
    @include generate-height($size, $breakpoint-name);
  }

  @each $size in $available-sizes {
    @include generate-min-height($size, $breakpoint-name);
  }

  @each $size in $available-sizes {
    @include generate-max-height($size, $breakpoint-name);
  }

  @each $size in $available-sizes {
    @include generate-line-height($size);
  }

  @include generate-size-property($size-properties, $size-values, $breakpoint-name);

  @include generate-size-percentage($size-properties, $breakpoint-name, $available-percentages);
}

.cs-ngx-slider.ngx-slider {
  margin: 10px 0 30px;

  .ngx-slider-bar {
    height: 6px;
    background: $bf-shade-20;
  }

  .ngx-slider-selection {
    background: $bf-shade-20;
  }

  .ngx-slider-pointer {
    width: 24px;
    height: 24px;
    top: auto;
    bottom: -11px;
    border-radius: 50%;
    background-color: $bf-shade-0;
    border: 3px solid $bf-shade-30;

    &:focus-visible {
      outline: none;
    }
  }

  .ngx-slider-pointer:after,
  .ngx-slider-bubble {
    display: none;
  }

  &.cs-ngx-slider-primary.ngx-slider {
    .ngx-slider-bar {
      background: $bf-shade-10;
    }
    .ngx-slider-selection {
      background: $bf-brand-primary-100;
    }

    .ngx-slider-pointer {
      background-color: $bf-shade-0;
      border: 3px solid $bf-brand-primary-100;
    }
  }

  &.cs-ngx-slider-with-bubble.ngx-slider {
    @extend .cs-ngx-slider;

    .ngx-slider-bubble {
      display: block;
    }
  }

  &.cs-ngx-slider-with-bubble-underline.ngx-slider {
    @extend .cs-ngx-slider;

    .ngx-slider-bubble {
      bottom: -28px !important;
      font-size: 12px !important;
      display: block;
    }
  }
}

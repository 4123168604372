.cs-bg-repeat {
  background-repeat: repeat;
}

.cs-bg-repeat-none {
  background-repeat: no-repeat;
}

.cs-bg-repeat-inherit {
  background-repeat: inherit;
}

.cs-bg-repeat-initial {
  background-repeat: initial;
}

.cs-bg-repeat-revert {
  background-repeat: revert;
}

.cs-bg-repeat-unset {
  background-repeat: unset;
}

.cs-spinner {
  color: transparent !important;
  pointer-events: none;
  position: relative;

  > * {
    opacity: 0;
  }

  &::after {
    $size: 16px;
    $b-size: 2px;

    content: '';
    animation: rotateFully 0.5s infinite linear;
    border: $b-size solid $bf-shade-15;
    border-color: transparent transparent $bf-shade-100 $bf-shade-100;
    border-radius: 100%;
    display: block;
    height: $size;
    width: $size;
    position: absolute;
    left: calc(50% - #{($size + $b-size) * 0.5});
    top: calc(50% - #{($size + $b-size) * 0.5});
  }

  &-xl {
    $size: 48px;
    display: block;
    width: $size;
    height: $size;

    &:after {
      width: $size;
      height: $size;
      top: 0;
      left: 0;
    }
  }

  &-sm {
    &:after {
      $size: 10px;
      width: $size;
      height: $size;
      top: 2px;
      left: 0;
      border-width: 1px;
    }
  }
}

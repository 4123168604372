@import 'projects/cobiro-styles/variables';

// TODO: Get rid of the ones here, improve and put in Cobiro Styles inside layout.scss
.page-wrap {
  padding: 40px;
  min-height: fit-content;
  position: relative;

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    padding: 30px 0;
    margin-bottom: 80px;
  }
}

.default-wrapper {
  padding-left: 50px;
  padding-right: 50px;
  max-width: 1060px;
  margin: 0 auto;

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 0;
  }
}

@mixin respond-above($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin scrollbar() {
  @-moz-document url-prefix() {
    scrollbar-color: $bf-shade-8 $bf-shade-2;
  }
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: $bf-shade-2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bf-shade-8;
    border-radius: 20px;
    border: 3px solid $bf-shade-2;
  }
}

@mixin responsive-class($class-prop-name, $available-values, $class-properties, $unit) {
  @each $media-query in $media-queries {
    @each $value in $available-values {
      .cs-#{$class-prop-name}-#{$value}#{if($media-query==xs,'','-' + $media-query)} {
        @if ($media-query == xs) {
          @include responsive-class-properties-generator($value, $class-properties, $unit);
        } @else {
          @include respond-above($media-query) {
            @include responsive-class-properties-generator($value, $class-properties, $unit);
          }
        }
      }
    }
  }
}

@mixin responsive-class-properties-generator($value, $class-properties, $unit) {
  $responsive-properties: map-get($class-properties, 'responsive');
  $additional-properties: map-get($class-properties, 'additional');
  @if $responsive-properties {
    @each $property-name in $responsive-properties {
      #{$property-name}: #{$value}#{if($unit, $unit, '')};
    }
  }
  @if $additional-properties {
    @each $property-name, $property-value in $additional-properties {
      #{$property-name}: $property-value;
    }
  }
}

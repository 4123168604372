@keyframes bg-shade-10-out-in-out {
  0% {
    background-color: $bf-shade-0;
  }
  75% {
    background-color: $bf-shade-10;
  }
  100% {
    background-color: $bf-shade-0;
  }
}

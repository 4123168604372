@import './mat-chip-list';

// Remember to use with <mat-form field appearance="outline"> attribute
// Material's outline appearance = Bifrost shade palette
.cs-mat-form-field {
  &.mat-form-field-appearance-outline {
    .mat-form-field-outline-thick {
      color: $bf-shade-25 !important;
    }
    .mat-mdc-form-field-infix {
      border-top-width: initial;
      padding: 9px 0;
      font-size: $bf-text-font-size-2;
    }

    .mat-mdc-select-arrow-wrapper {
      transform: translateY(0);
    }

    .mat-mdc-form-field-wrapper {
      padding: 0;
      margin: 0;
    }
  }

  mat-form-field {
    &.search-input {
      $leftPadding: 10px;
      position: sticky;
      z-index: 1;
      top: 0;
      background-color: $bf-shade-0;
      color: $bf-shade-100;

      input,
      mat-label {
        padding-left: $leftPadding;
        width: calc(100% - #{$leftPadding});
      }
    }
  }

  .mat-mdc-select {
    font-family: $f-family-basic;
    font-size: $bf-text-font-size-2;
  }

  &.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
    color: $bf-shade-60 !important;
  }
}

// Remember to use with <mat-form-field appearance="none">
.cs-mat-form-field-with-chip-list-sm {
  $inner-input-height-sm: 32px;
  $min-total-input-height: 48px;
  $vertical-break: 8px;
  $horizontal-break: 10px;

  @extend .cs-mat-chip-list-sm;

  border-radius: 5px;
  width: 100%;
  padding: 0 $horizontal-break $vertical-break $horizontal-break;
  min-height: $min-total-input-height;
  word-break: break-word;

  .mat-mdc-form-field-wrapper {
    padding-bottom: 0 !important;

    .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
      padding: 0;
      border-top: none;
    }
  }

  &.cs-mat-form-field-with-chip-list-shade {
    border: 1px solid $bf-shade-15;

    .mat-chip-list .mat-chip-list-wrapper {
      .mat-chip {
        background-color: $bf-shade-15;
        color: $bf-shade-100;

        .mat-chip-remove {
          color: $bf-shade-100;
        }
      }
    }
  }
}

.cs-alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  border-radius: $radius;
  margin: 0;
  line-height: font-size + 2px;
  padding: 16px;
  font-size: $bf-text-font-size-2;
}

.cs-alert-default {
  @extend .cs-c-shade-0, .cs-bg-shade-100;
}

.cs-alert-warn {
  @extend .cs-c-warning, .cs-bg-warning-20;
}

.cs-alert-error {
  @extend .cs-c-error, .cs-bg-error-20;
}

.cs-alert-success {
  @extend .cs-c-success, .cs-bg-success-20;
}

.cs-alert-info {
  @extend .cs-c-primary, .cs-bg-primary-20;
}

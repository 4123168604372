@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

@keyframes expandAndContract {
  from {
    left: 0;
    width: 0;
  }
  50% {
    right: 0;
    width: 100%;
  }
  to {
    right: 0;
    width: 0;
  }
}

.ul-reset {
  list-style: none;
  margin-bottom: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

// TODO: Used in a few places - think what to do with it
.justify-self-center {
  justify-self: center;
}

.break-word {
  word-break: break-word;
}

.d-contents {
  display: contents;
}

.d-grid {
  display: grid;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-100 {
  opacity: 1 !important;
}

.flex-1 {
  flex: 1;
}

.flex-1-0 {
  flex: 1 0;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

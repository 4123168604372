@import 'mixins';

// TODO: Uniformize paddings and margins - right now their sizes are not clear (multiples of 4, 5 or 8?)
$available-paddings: (
  0,
  1,
  2,
  3,
  4,
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  60,
  70,
  80,
  90,
  95,
  100
);
$available-margins: (0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 40, 45, 50, 55, 60, 80, 100);

$paddings: (
  p: (
    padding,
  ),
  pl: (
    padding-left,
  ),
  pr: (
    padding-right,
  ),
  pt: (
    padding-top,
  ),
  pb: (
    padding-bottom,
  ),
  ph: (
    padding-left,
    padding-right,
  ),
  pv: (
    padding-top,
    padding-bottom,
  ),
);

@each $padding-name, $padding-props in $paddings {
  @include responsive-class(
    $padding-name,
    $available-paddings,
    (
      responsive: $padding-props,
    ),
    px
  );
}

$margins: (
  m: (
    margin,
  ),
  ml: (
    margin-left,
  ),
  mr: (
    margin-right,
  ),
  mt: (
    margin-top,
  ),
  mb: (
    margin-bottom,
  ),
  mh: (
    margin-left,
    margin-right,
  ),
  mv: (
    margin-top,
    margin-bottom,
  ),
);

@each $margin-name, $margin-props in $margins {
  @include responsive-class(
    $margin-name,
    $available-margins,
    (
      responsive: $margin-props,
    ),
    px
  );
}

@mixin generate-top($size) {
  $pixel-size: #{$size}px;

  .cs-top-#{$size} {
    top: $pixel-size;
  }
}

@mixin generate-bottom($size) {
  $pixel-size: #{$size}px;

  .cs-bottom-#{$size} {
    bottom: $pixel-size;
  }
}

@mixin generate-left($size) {
  $pixel-size: #{$size}px;

  .cs-left-#{$size} {
    left: $pixel-size;
  }
}

@mixin generate-right($size) {
  $pixel-size: #{$size}px;

  .cs-right-#{$size} {
    right: $pixel-size;
  }
}

@each $size in $available-margins {
  @include generate-top($size);
  @include generate-right($size);
  @include generate-bottom($size);
  @include generate-left($size);
}

.cs-pb-0-last-item:last-of-type {
  padding-bottom: 0 !important;
}

.cs-mb-0-last-item:last-of-type {
  margin-bottom: 0 !important;
}

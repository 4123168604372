// TODO: Verify naming convention to match Bifrost
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  background-color: $bf-brand-primary-100 !important;
  border-color: $bf-brand-primary-100 !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  --mdc-checkbox-selected-checkmark-color: #ffffff !important;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $bf-brand-primary-100 !important;
}

.mat-checkbox .mat-checkbox-frame {
  border-color: $bf-brand-primary-100 !important;
  background-color: $bf-shade-0 !important;
}

.mat-checkbox .mat-checkbox-disabled .mat-checkbox .mat-checkbox-frame {
  border-color: $bf-shade-40 !important;
  background-color: $bf-shade-10 !important;
}

.mat-checkbox-disabled {
  &.mat-checkbox-checked,
  &.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      border-color: $bf-shade-40 !important;
      background-color: $bf-shade-10 !important;
    }
  }

  &:not(.mdc-checkbox--selected) {
    .mat-checkbox-frame {
      border-color: $bf-shade-40 !important;
      background-color: $bf-shade-10 !important;
    }
  }
}

.mat-mdc-checkbox .mat-checkbox-label {
  font-family: $f-family-basic;
  font-weight: $bf-font-weight-text;
  font-size: $bf-text-font-size-1;
  line-height: 100px; // TODO: Align typography with Bifrost
}

.cs-mat-checkbox {
  display: block !important;

  .mdc-checkbox {
    padding-left: 0 !important;
    padding-right: 8px !important;
  }

  .mdc-checkbox__background {
    left: 0 !important; // Material changed the way of displaying the checkbox - now it uses transform & absolute
  }

  &.is-invalid {
    &.mat-checkbox-checked,
    &.mat-checkbox-indeterminate {
      .mdc-checkbox__background {
        border-color: $bf-error-60 !important;
        background-color: $bf-error-10 !important;
      }
    }

    &:not(.mdc-checkbox--selected) {
      .mdc-checkbox-frame {
        border-color: $bf-error-60 !important;
        background-color: $bf-error-10 !important;
      }
    }
  }

  &.cs-mat-checkbox-shade-10 {
    &:not(.mdc-checkbox--selected) {
      .mdc-checkbox__background {
        border-color: $bf-shade-10 !important;
        background-color: $bf-shade-10 !important;
      }
    }
  }

  &.cs-mat-checkbox-without-ripple {
    .mdc-checkbox__ripple {
      display: none;
    }
  }
}

// Generic checkbox
.cs-checkbox-control {
  font-family: arial;
  display: block;
  padding-left: 20px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 16px;
}

.cs-checkbox-control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.cs-checkbox-control_indicator {
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  background: $bf-shade-0;
  border: 1px solid $bf-brand-primary-100;
  border-radius: 2px;
}

.cs-checkbox-control:hover input ~ .cs-checkbox-control_indicator,
.cs-checkbox-control input:focus ~ .cs-checkbox-control_indicator {
  background: $bf-brand-primary-30;
}
.cs-checkbox-control input:checked ~ .cs-checkbox-control_indicator {
  background: $bf-brand-primary-100;
}

.cs-checkbox-control:hover input:not([disabled]):checked ~ .cs-checkbox-control_indicator,
.cs-checkbox-control input:checked:focus ~ .cs-checkbox-control_indicator {
  background: $bf-brand-primary-100;
}

.cs-checkbox-control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}

.cs-checkbox-control input:checked ~ .cs-checkbox-control_indicator:after {
  display: block;
}

.cs-checkbox-control-checkbox .cs-checkbox-control_indicator:after {
  left: 5px;
  top: 1px;
  width: 3px;
  height: 8px;
  border: solid $bf-shade-0;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  transform: rotate(45deg);
}

// TODO: There is another way of styling checkboxes - this one is present in Ecommerce's S&D
// TODO: Refactor one of the solutions out of app.cobiro, probably this one since it's used in 2 components only.
.cs-checkbox {
  position: relative;
  padding-left: 24px;

  input {
    display: none;
  }

  label {
    &:before,
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      z-index: 1;
    }
    &:before {
      width: 16px;
      height: 16px;
      background: $bf-brand-primary-10;
      border-radius: 2px;
      z-index: 0;
    }

    &:after {
      transform: rotate(45deg);
      border-style: solid;
      border-width: 0 1px 1px 0;
      padding: 4px 1px 4px 3px;
      vertical-align: middle;
      margin-top: -6px;
      margin-left: 6px;
      z-index: 1;
      opacity: 0;
    }
  }

  input:checked + label {
    &:after {
      opacity: 1;
    }
  }
}

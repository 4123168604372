ng-select {
  &:focus {
    .ng-placeholder {
      color: transparent;
    }
  }

  &.cs-ng-select {
    width: 100%;
    position: relative;

    &.ng-select-focused {
      .ng-placeholder {
        color: transparent;
      }
    }
    &.ng-select-multiple {
      .ng-select-container {
        height: 100%;

        .ng-value {
          margin: 3px;
        }
      }
    }
    .ng-select-container {
      @extend .cs-input;

      height: 40px;
      line-height: 40px;

      .ng-spinner-loader {
        right: 15px;
      }

      .ng-value-container .ng-input input {
        padding-left: 15px;
        padding-right: 15px;
        cursor: text;
      }

      .ng-value-container {
        display: flex;
        align-items: center;
        width: 100%;

        .ng-placeholder {
          @extend .cs-text-1;
        }
        .ng-input {
          flex: none !important;
        }
        .ng-input input,
        .ng-value {
          @extend .cs-text-2;
        }

        .ng-value {
          border-radius: 15px;
          padding: 3px 15px;
          @extend .cs-bg-primary-10;
          @extend .cs-c-primary;
          .ng-value-icon {
            margin-right: 5px;
          }
        }
      }

      .ng-clear-wrapper {
        display: none;
      }
    }

    .ng-has-value .ng-placeholder {
      display: none;
    }

    &.is-invalid {
      .ng-select-container {
        border: 1px solid $bf-error-30;
      }
    }

    .ng-clear-wrapper {
      z-index: 2;

      .ng-clear {
        display: none;
      }
    }

    .ng-spinner-loader {
      margin-top: auto;
      margin-bottom: auto;
      border: 2px solid $bf-shade-100;
      border-color: transparent transparent $bf-shade-100 $bf-shade-100 !important;
    }

    &.ng-select-focused {
      .ng-select-container {
        box-shadow: none;
        border-color: $bf-shade-25;
      }
    }

    &.ng-select-opened {
      .ng-select-container {
        border-radius: $radius;
        border-color: $bf-shade-25;
      }
    }

    .ng-dropdown-panel {
      margin-top: 2px;

      .ng-dropdown-panel-items {
        padding: 0 10px;
        background: $bf-shade-0;
        border-radius: $radius;
        border: 1px solid $bf-shade-10;

        div div:first-child {
          border-top: 0;
        }

        .ng-option {
          height: 40px;
          padding: 8px 0;
          border-top: 1px solid $bf-shade-10;

          &.ng-option-disabled {
            color: $bf-shade-40;
          }

          &.ng-option-marked {
            background: none;
            color: $bf-shade-100;
          }

          &.ng-option-selected {
            background: none;
          }
        }
      }
    }
  }
}

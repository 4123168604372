.cs-popover-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  position: relative;

  &:hover {
    .cs-popover-content {
      z-index: 10;
      opacity: 1;
      visibility: visible;
      transform: translate(0, 20px);
      background: $bf-shade-0;
      transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
    }
  }
}

.cs-popover-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: $radius;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: $bf-shade-0;
  padding: 20px;
  box-shadow: $b-shadow-card;
  min-width: 100px;
  top: 50%;

  &-title {
    @extend .cs-title-8;
  }

  &-text {
    @extend .cs-text-2;
  }

  &:before {
    position: absolute;
    z-index: -1;
    content: '';
    right: calc(50% - 10px);
    top: -10px;
    filter: drop-shadow(0 0 0 $bf-shade-50);
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $bf-shade-0 transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }
}

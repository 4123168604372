$padding: 30px;

.cs-card {
  border: 1px solid $bf-shade-10;
  border-radius: 5px;
  padding: $padding;
  background-color: $bf-shade-0;
  box-shadow: $b-shadow;

  &-header {
    display: flex;
    margin-bottom: 24px;

    &-image {
      margin-right: 16px;
    }
  }

  &-title {
    @extend .cs-c-shade-60, .cs-text-2;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &-footer {
    padding: 21px $padding;
  }

  // for leveling with card bottom padding
  // TODO: solution for working with buttons to get rid of additional padding from footer (to consider)
  //& > &-footer:last-child {
  //  margin-bottom: -($padding + 21px) / 2;
  //}
}

.cs-tile {
  @extend .cs-card;
  box-shadow: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
  transition: all ease-in-out 0.25s;

  &:hover {
    box-shadow: $b-shadow;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.6;
  }

  &:focus {
    outline: $bf-shade-100 auto 1px;
  }

  &-long {
    @extend .cs-text-2, .cs-c-shade-100;

    height: 60px;
    width: 100%;
    margin-bottom: 10px;
    padding: 20px;
    box-sizing: border-box;
  }

  &-checkbox {
    position: relative;
    cursor: default;

    &-input {
      position: absolute;
      top: 10px;
      right: 10px;

      &-relative {
        top: 0;
        right: 0;
        position: relative;
      }
    }
  }
}

.cs-tile-label {
  @extend .cs-c-shade-60;

  display: flex;
  align-items: center;
  padding: 4px 8px;
  position: absolute;
  top: 10px;
  right: -6px;
  height: 20px;
  background-color: $bf-shade-6;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    top: 20px;
    right: 0;
    border-width: 0 0 6px 6px;
    border-style: solid;
    border-color: transparent $bf-shade-30;
  }

  &.cs-tile-label-blue {
    @extend .cs-c-shade-0;
    height: 24px;
    padding: 4px 10px;
    background-color: $bf-brand-primary-100;
    border-radius: 5px 5px 0 5px;

    &:before {
      top: 24px;
      border-color: transparent $bf-brand-primary-100;
    }
  }
}

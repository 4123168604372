.cs-bg-size-contain {
  background-size: contain;
}

.cs-bg-size-cover {
  background-size: cover;
}

.cs-bg-size-auto {
  background-size: auto;
}

.cs-bg-size-inherit {
  background-size: inherit;
}

.cs-bg-size-initial {
  background-size: initial;
}

.cs-bg-size-revert {
  background-size: revert;
}

.cs-bg-size-unset {
  background-size: unset;
}

@use '@angular/material' as mat;

// Be sure that you only ever include 'mat-core' mixin once!
// it should not be included for each theme.
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// define a real custom palette (using http://mcg.mbitson.com)
$md-cobiro: (
  // Based on Cobiro's Bifrost primary palette
  50: #e5efff,
  // $bf-brand-primary-10
  100: #ccdffe,
  200: #b2d0ff,
  300: #99c0ff,
  400: #7fb1ff,
  500: #65a1ff,
  600: #4c91fe,
  700: #3282ff,
  800: #1972ff,
  900: #0063ff,
  // $bf-brand-primary-100
  A100: #e5efff,
  // $bf-brand-primary-10
  A200: #b2d0ff,
  // $bf-brand-primary-30
  A400: #7fb1ff,
  // $bf-brand-primary-50
  A700: #65a1ff,
  // $bf-brand-primary-60
  contrast:
    (
      50: #e7e7e7,
      // $bf-shade-10
      100: #cfcfcf,
      200: #b7b7b7,
      300: #9f9f9f,
      400: #888888,
      500: #707070,
      600: #585858,
      700: #404040,
      800: #282828,
      900: #111111,
      // $bf-shade-100
      A100: #e7e7e7,
      // $bf-shade-10
      A200: #b7b7b7,
      // $bf-shade-30
      A400: #888888,
      // $bf-shade-50
      A700: #707070,
      // $bf-shade-60
    )
);

$md-cobiro-secondary: (
  50: #f5e5ff,
  // $bf-brand-secondary-10
  100: #ebccfe,
  200: #e1b2ff,
  300: #d799ff,
  400: #cd7fff,
  500: #c465ff,
  600: #ba4cfe,
  700: #b032ff,
  800: #a619ff,
  900: #9d00ff,
  // $bf-brand-secondary-100
  A100: #f5e5ff,
  // $bf-brand-secondary-10
  A200: #e1b2ff,
  // $bf-brand-secondary-30
  A400: #cd7fff,
  // $bf-brand-secondary-50
  A700: #c465ff,
  // $bf-brand-secondary-60
  contrast:
    (
      // Same as the primary's contrast palette
      50: #e7e7e7,
      // $bf-shade-10
      100: #cfcfcf,
      200: #b7b7b7,
      300: #9f9f9f,
      400: #888888,
      500: #707070,
      600: #585858,
      700: #404040,
      800: #282828,
      900: #111111,
      // $bf-shade-100
      A100: #e7e7e7,
      // $bf-shade-10
      A200: #b7b7b7,
      // $bf-shade-30
      A400: #888888,
      // $bf-shade-50
      A700: #707070,
      // $bf-shade-60
    ),
);

// mandatory stuff for theming
$bv-palette-primary: mat.define-palette($md-cobiro);
$bv-palette-accent: mat.define-palette($md-cobiro-secondary);

// include the custom theme components into a theme object
$bv-theme: mat.define-light-theme($bv-palette-primary, $bv-palette-accent);

// include the custom theme object into the angular material theme
@include mat.all-legacy-component-themes($bv-theme);

.mat-icon-button {
  &.cdk-program-focused {
    background: transparent;

    .mat-button-focus-overlay {
      display: none;
    }
  }
}

.cdk-global-overlay-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
}

.mat-accordion .mat-expansion-panel .mat-expansion-panel-content {
  cursor: default;
}

.cdk-drag:hover button.cs-btn {
  cursor: pointer !important;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0.3;
}

.mat-mdc-menu-panel {
  max-width: 565px !important;
  background: white;
}

.mat-mdc-menu-item {
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px !important;
  font-weight: 400 !important;
  background: transparent !important;
  color: #000000de !important;
  &:hover {
    background: $bf-shade-6 !important;
  }
  .mdc-list-item__primary-text {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

@keyframes rotateFully {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
  }
}

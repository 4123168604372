$cs-avatar-size: 40px;

.cs-avatar {
  display: flex;
  background-color: $bf-shade-2;
  box-shadow: 0 1px 2px 1px $bf-shade-6;
  width: $cs-avatar-size;
  height: $cs-avatar-size;
  align-items: center;
  justify-content: center;

  &.cs-avatar-sm {
    width: 20px;
    height: 20px;
    padding: unset !important;
    border-radius: 5px !important;
  }

  &.cs-avatar-xxl {
    width: 80px;
    height: 80px;
    padding: unset !important;
    border-radius: 5px !important;
  }

  &.cs-avatar-text {
    padding: 20px;
  }

  &.cs-avatar-round {
    border-radius: 50px;
    &.cs-avatar-sm {
      border-radius: 20px !important;
    }
  }

  &.cs-avatar-rounded {
    border-radius: 10px;
  }
}

@import url('https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/6.0.0/adyen.css');
@import '~@typeform/embed/build/css/popup.css';

// TODO: Must stay exactly as it is
.grecaptcha-badge {
  visibility: hidden;
}

// TODO: Do not touch - this is styling for an external package, and cannot be done otherwise
#cb-adyen-3ds-webcomponent {
  width: fit-content !important;
  height: fit-content !important;
  padding: 20px !important;
}

.cs-list {
  padding: 0;
  margin: 0;
  list-style-position: inside;

  &-item {
    @extend .cs-text-2, .cs-c-shade-60;
    margin-bottom: 20px;
    padding: 4px;
  }

  &-bordered {
    .cs-list-item {
      border: 1px solid $bf-shade-10;
      border-radius: $radius;
    }
  }

  &-none {
    list-style-type: none;
  }

  &-gray {
    .cs-list-item.active,
    .cs-list-item:hover {
      background-color: $bf-shade-6;
    }
  }
}

.cs-list-checkmark {
  padding-left: 25px;
  list-style-position: outside;

  .cs-list-item-text {
    position: absolute;
    top: 0;
  }

  .cs-list-item {
    position: relative;

    &:before {
      content: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%2327d07b;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(6796 5099)'%3E%3Cg transform='translate(-6796 -5099)'%3E%3Ccircle class='a' cx='8' cy='8' r='8' transform='translate(0 0)'/%3E%3C/g%3E%3Cg transform='translate(-6791.2 -5093.667)'%3E%3Cg transform='translate(0 0)'%3E%3Cpath class='b' d='M6.315,57.537a.826.826,0,0,0-1.185,0L2.169,60.557l-.74-.755a.826.826,0,0,0-1.185,0,.866.866,0,0,0,0,1.208l1.333,1.359a.826.826,0,0,0,1.185,0l3.554-3.624A.866.866,0,0,0,6.315,57.537Z' transform='translate(0.002 -57.286)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      position: absolute;
      top: 2px;
      left: -24px;
    }
  }
}

.cs-popup {
  padding: 40px;
  text-align: center;
  position: relative;

  &-with-footer {
    padding-bottom: 0;
  }

  &-icon {
    width: 40px;
    height: 40px;
  }

  &-title {
    @extend .cs-title-5, .cs-c-shade-100;

    margin-top: 20px;
  }

  &-message {
    @extend .cs-text-1, .cs-c-shade-60;

    margin-top: 12px;
  }

  &-button {
    margin-top: 40px;
    width: 80%;
    padding: 12px;
  }

  &-close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.cs-popup-footer {
  width: 100%;
  background: $bf-shade-0;
  bottom: 0;
  left: 0;
  padding-bottom: 40px;
  padding-top: 20px;
}

@media only screen and (max-width: 768px) {
  .cdk-global-overlay-wrapper {
    padding: 10px 0 0;
  }
}

.cs-confirmation-popup {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 40px;

  &-content {
    margin-bottom: 40px;

    &-title {
      @extend .cs-title-5;
    }

    &-text {
      @extend .cs-text-1, .cs-c-shade-60;

      margin-top: 12px;
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.cs-stepper-popup {
  border-radius: 10px;
  display: flex;
  position: relative;
  &-toolbar {
    position: absolute;
    width: 100%;
    height: 30px;
  }

  &-content {
    background-color: $bf-shade-0;
    padding: 70px 40px 40px;
  }

  &-back-button {
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    .back-icon {
      margin-right: 10px;
    }
  }
}

$animation-quick: 150ms;
$animation-regular: 300ms;
$animation-long: 500ms;

$available-animation-duration-names: (quick, regular, slow);
$available-animation-types: (ease, ease-out);

$available-animation-speeds-full-map: (
  quick: $animation-quick,
  regular: $animation-regular,
  slow: $animation-long,
);

@mixin transition-class-generator($durations, $types) {
  @each $durationName in $durations {
    @each $typeValue in $types {
      .cs-transition-#{$durationName}-#{$typeValue} {
        transition: map-get($available-animation-speeds-full-map, $durationName) #{$typeValue};
      }
    }
  }
}

@include transition-class-generator(
  $available-animation-duration-names,
  $available-animation-types
);

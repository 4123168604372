.cs-mat-accordion {
  .mat-expansion-panel {
    border-radius: 5px !important;
    margin-bottom: 10px;
    border: 1px solid $bf-shade-10;
    box-shadow: none !important;
  }

  .mat-expansion-panel-header-title {
    @extend .cs-text-2, .cs-c-shade-100;
  }

  // TODO: Duplicated - find, refactor/move
  .mat-expansion-panel-header-description {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .mat-expansion-panel-header {
    height: 52px;
    padding: 20px;
    box-sizing: border-box;
  }

  .mat-expansion-panel-body {
    padding: 20px;
  }

  .mat-expansion-indicator {
    font-size: 8px;

    &:after {
      @extend .cs-c-shade-100;

      border-width: 0 1px 1px 0;
      padding: 2px;
      margin-top: -3px;
    }
  }
}

.mat-expansion-indicator {
  &::after {
    @extend .cs-c-shade-100;

    border-width: 0 1px 1px 0;
    padding: 2px;
    margin-top: -3px;
  }
}

@import '../utilities/animations';

.cs-progress {
  width: 100px;
  height: 8px;
  appearance: none;
  vertical-align: baseline;
  margin-right: 10px;
}

.cs-progress[value]::-webkit-progress-bar {
  border-radius: 100px;
  background-color: $bf-shade-4;
}

.cs-progress[value]::-webkit-progress-value {
  border-radius: 100px;
  background-color: $bf-success-50;
  transition: width $animation-regular ease-in;
}

.cs-progress-group {
  display: flex;
  flex-direction: row;
  align-items: center;

  color: $bf-success-50;
  font-family: $f-family-basic;
  font-size: $bf-text-font-size-2;
  font-weight: $bf-font-weight-title;
}

.cs-progress-label {
  display: flex;
  width: 30px;
  color: $bf-success-50;
  font-family: $f-family-basic;
  font-size: $bf-text-font-size-2;
  font-weight: $bf-font-weight-title;
}

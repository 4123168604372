$outer-circle-size: 100px;
$inner-circle-size: 60px;

.cs-emoji-circle {
  border: ($outer-circle-size - $inner-circle-size) * 0.5 solid transparent;
  width: $outer-circle-size;
  height: $outer-circle-size;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;

  &.cs-emoji-circle-primary {
    background: $bf-brand-primary-40;
    border-color: $bf-brand-primary-10;
  }

  &.cs-emoji-circle-secondary {
    background: $bf-brand-secondary-20;
    border-color: $bf-brand-secondary-10;
  }

  &.cs-emoji-circle-tertiary {
    background: $bf-brand-tertiary-20;
    border-color: $bf-brand-tertiary-10;
  }

  &.cs-emoji-circle-quaternary {
    background: $bf-brand-quaternary-30;
    border-color: $bf-brand-quaternary-10;
  }
}

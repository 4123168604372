.cs-hero {
  font-family: $f-family-basic;
  border-bottom: 1px solid $bf-shade-10;
  margin-bottom: -1px;
  min-height: 150px;
}

.cs-hero-container {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex: 0 0 100%;
}

.cs-hero-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cs-hero-menu {
  padding-top: 40px;
  padding-bottom: 40px;
  flex-basis: 250px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &-item {
    margin-bottom: 8px;
  }

  .cs-hero-menu-item:last-of-type {
    margin-bottom: 0;
  }
}

.cs-hero-image {
  margin-right: 30px;
  max-width: 60px;
  max-height: 60px;
}

.cs-hero-body {
  min-height: 150px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.cs-hero-title {
  @extend .cs-title-3, .cs-c-shade-100;
}

.cs-hero-description {
  @extend .cs-text-2, .cs-c-shade-60;
  margin: 0;
}

.cs-asset-google-link {
  background: url('../assets/google/cobiro-google-link.jpg') no-repeat;
}

.cs-asset-google-link-error {
  background: url('../assets/google/cobiro-google-link-error.jpg') no-repeat;
}

.cs-asset-google-linked {
  background: url('../assets/google/cobiro-google-linked.jpg') no-repeat;
}

.cs-asset-google-sign-in {
  background: url('../assets/google/cobiro-google-sign-in.png') no-repeat;
}

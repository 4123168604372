$bf-title-font-size-1: 48px !default;
$bf-title-line-height-1: 58px !default;

$bf-title-font-size-2: 40px !default;
$bf-title-line-height-2: 48px !default;

$bf-title-font-size-3: 32px !default;
$bf-title-line-height-3: 42px !default;

$bf-title-font-size-4: 24px !default;
$bf-title-line-height-4: 32px !default;

$bf-title-font-size-5: 18px !default;
$bf-title-line-height-5: 26px !default;

$bf-title-font-size-6: 16px !default;
$bf-title-line-height-6: 24px !default;

$bf-title-font-size-7: 14px !default;
$bf-title-line-height-7: 21px !default;

$bf-title-font-size-8: 12px !default;
$bf-title-line-height-8: 18px !default;

$bf-title-font-size-9: 10px !default;
$bf-title-line-height-9: 16px !default;

$bf-text-font-size-1: 14px !default;
$bf-text-line-height-1: 21px !default;

$bf-text-font-size-2: 12px !default;
$bf-text-line-height-2: 18px !default;

$bf-text-font-size-3: 10px !default;
$bf-text-line-height-3: 16px !default;

$bf-font-weight-title: 600 !default;
$bf-font-weight-text: 400 !default;

$f-family-basic: 'Poppins', sans-serif;

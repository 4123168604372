.cs-topbar {
  width: 100%;
  height: $top-bar-height;

  display: flex;
  padding: 10px 20px;
  background-color: $bf-shade-0;
  border-bottom: 1px solid $bf-shade-10;
}

// TODO: Extend in a separate task before publishing v1.8.0
.cs-sidebar {
  width: $sidebar-width;
  background-color: $bf-shade-0;
}

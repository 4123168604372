.cs-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  max-width: 1200px;
}

@media all and (min-width: 768px) {
  .cs-container {
    padding-left: 70px;
    padding-right: 70px;
  }
}

.cs-asset-bg-gradient-1 {
  background: url('../assets/backgrounds/cobiro-bg-gradient-1.png') no-repeat;
}

.cs-asset-bg-gradient-2 {
  background: url('../assets/backgrounds/cobiro-bg-gradient-2.jpg') no-repeat;
}

.cs-asset-bg-gradient-3 {
  background: url('../assets/backgrounds/cobiro-bg-gradient-3.png') no-repeat;
}

.cs-asset-bg-gradient-4 {
  background: url('../assets/backgrounds/bg-onboarding-box.svg') no-repeat;
  background-position: top right;
}

.cs-asset-bg-stars-1 {
  background: url('../assets/backgrounds/bg-best-plan-box.svg') no-repeat;
  background-size: contain;
  background-position: top right;
}

.cs-asset-bg-flow-1 {
  background: url('../assets/backgrounds/cobiro-bg-flow-1.jpg') no-repeat;
}

.cs-asset-bg-flow-2 {
  background: url('../assets/backgrounds/cobiro-bg-flow-2.jpg') no-repeat;
}

.cs-asset-bg-flow-3 {
  background: url('../assets/backgrounds/cobiro-bg-flow-3.jpg') no-repeat;
}

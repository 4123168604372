@import 'projects/cobiro-styles/bifrost/colors';
@import 'projects/cobiro-styles/bifrost/typography-vars';
@import 'projects/cobiro-styles/utilities/animations';
@import 'projects/cobiro-styles/variables';

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

app-root {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: $f-family-basic;
  font-size: $bf-text-font-size-1;
  line-height: normal;
  font-weight: $bf-font-weight-text;
  color: $bf-shade-60;

  input[type='text'],
  textarea {
    font-family: $f-family-basic;
  }

  button {
    font-family: $f-family-basic;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
  color: $bf-shade-100;
  font-weight: $bf-font-weight-title;
  font-family: $f-family-basic;
  line-height: normal;
  margin-top: 0;
}

a {
  color: $bf-shade-100;
  transition: color $animation-regular ease;
  text-decoration: underline;

  &:hover {
    color: $bf-shade-80;
  }

  &:focus {
    outline: none;
    color: $bf-shade-100;
  }
}

p {
  color: $bf-shade-60;
  margin-top: 0;
}
.table-responsive-container .empty-state-rows td {
  position: inherit !important;
}
@media (max-width: map-get($grid-breakpoints, 'md')) {
  .integration-stepper {
    width: 100vw !important;
  }
  .cs-mat-dialog {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .sidebar-menu {
    display: flex !important;
    z-index: 100 !important;
    position: absolute !important;
    overflow: hidden; /* Hide the element content, while height = 0 */
    height: 0;
    opacity: 0;
    transition: height 0ms 400ms, opacity 400ms 0ms;
  }
  .sidebar-menu.visibled {
    display: flex !important;
    z-index: 100 !important;
    position: absolute !important;
    height: auto;
    opacity: 1;
    transition: height 0ms 0ms, opacity 600ms 0ms;

    & nav {
      width: 100vw;
    }
  }

  .table-responsive-container {
    width: 100vw !important;
    overflow-x: scroll;
    & .empty-state-rows td {
      position: inherit !important;
    }
    & .mat-mdc-table-sticky {
      background-color: $bf-shade-2 !important;
    }
    & .mat-mdc-header-cell {
      background-color: $bf-shade-0 !important;
    }
    & .cs-mat-table.highlight-row .mat-mdc-row:hover .mat-mdc-cell {
      background-color: $bf-shade-4;
    }
  }
}

$utilities: (
  'width': (
    property: width,
    class: w,
    responsive: true,
    values: (
      25: 25%,
      33: 33%,
      50: 50%,
      66: 66%,
      75: 75%,
      100: 100%,
      auto: auto,
    ),
  ),
);

@import './bifrost/colors';

// TODO: DO NOT ADD ANY NEW COLORS HERE
// Cobiro variables

// Orange
$c-gradient-1: #fffb95;
$c-gradient-2: #ffda95;
$c-dark-orange: #6a4200;

// Shadow
$b-shadow: 0 3px 20px $bf-shade-6;
$b-shadow-card: 0 3px 20px $bf-shade-10;
$b-shadow-hover: 0 3px 20px $bf-shade-40;

//  Border & Border-radius defaults
$radius: 5px !default;

// Breakpoints currently in use
$media-queries: (xs, sm, md, lg, xl, xxl);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

// Grid setup
$grid-columns: 12;
$grid-gutter-width: 20px !default;
$enable-grid-classes: true !default;

// App layout
$top-bar-height: 60px;
$sidebar-width: 240px;

// Z-index
$z-index-max: 1000000; // This is the absolutely highest z-index that should be used, anywhere
// Use it only for global loading state

.mat-dialog-container {
  padding: 0 !important;
}

// TODO: This is actually a mat-dialog - adjust naming of the file and of this class to include 'mat'
.cs-mat-dialog-no-box {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    box-shadow: none !important;
    padding: 40px !important;
  }
}
.cs-mat-dialog {
  .mat-dialog-container {
    border-radius: $radius;
    background-color: transparent;
    box-shadow: none;
  }

  &-right {
    right: 0;
    position: absolute !important;

    .mat-dialog-container {
      box-shadow: none;
      border-radius: 0;
      background-color: $bf-shade-0;
      height: 100%;
    }
  }
}

@mixin fill-color-utility-class-generator($fill-colors, $fill-opacities) {
  @each $color in $fill-colors {
    @each $opacity in $fill-opacities {
      .cs-fill-#{$color}-#{$opacity} {
        $color-name: #{$color}-#{$opacity};
        fill: map-get($bifrost-colors, $color-name);

        * {
          fill: map-get($bifrost-colors, $color-name);
        }
      }

      .cs-fill-i-#{$color}-#{$opacity} {
        $color-name: #{$color}-#{$opacity};
        fill: map-get($bifrost-colors, $color-name) !important;

        * {
          fill: map-get($bifrost-colors, $color-name) !important;
        }
      }
    }
  }
}

@include fill-color-utility-class-generator($bifrost-colors-list, $bifrost-opacities-list);
@include fill-color-utility-class-generator(
  $bifrost-shade-colors-list,
  $bifrost-shade-opacities-list
);

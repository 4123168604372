.cs-arrow {
  &:after {
    border-style: solid;
    border-width: 0 2px 2px 0;
    content: '';
    display: inline-block;
    padding: 4px;
    vertical-align: middle;
    margin-top: -4px;
    margin-left: 2px;
  }

  &-up {
    &:after {
      transform: rotate(225deg);
    }
  }

  &-down {
    &:after {
      transform: rotate(45deg);
    }
  }

  &-left {
    &:after {
      transform: rotate(135deg);
    }
  }

  &-right {
    &:after {
      transform: rotate(-45deg);
    }
  }

  &-sm {
    &:after {
      border-width: 0 1px 1px 0;
      padding: 3px;
      margin-top: -3px;
    }
  }
}

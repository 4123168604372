.cs-navbar {
  font-family: $f-family-basic;
  display: flex;
}

.cs-navbar-item {
  @extend .cs-title-8, .cs-c-shade-60;

  display: inline-block;
  padding: 8px 10px;
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  text-decoration: none;

  &.active {
    @extend .cs-c-shade-100;

    &:after {
      @extend .cs-bg-primary-100;
      content: '';
      display: inline-block;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      border-radius: $radius $radius 0 0;
    }
  }

  &:hover {
    @extend .cs-c-shade-100;
  }
}

.cs-mat-table {
  width: 100%;
  border-collapse: collapse;
  background-color: transparent;

  tbody:before {
    display: block;
    text-indent: -99999px;
  }

  .mat-mdc-header-cell,
  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    font-family: $f-family-basic;
    font-size: $bf-text-font-size-2;
    line-height: 1.5;
    color: $bf-shade-60;
    padding: 15px;

    &:first-of-type {
      padding-left: 30px;
    }

    &:last-of-type {
      padding-right: 30px;
    }

    &.no-border {
      border-bottom: none;
    }

    &.color-primary {
      color: $bf-shade-100;
    }
  }

  .mat-mdc-header-cell {
    border-bottom: 1px solid $bf-shade-10;
    font-weight: 400;
  }

  .mat-mdc-cell {
    font-weight: 300;
  }

  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    border-bottom: none;
  }

  .mat-mdc-header-row,
  .mat-mdc-footer-row {
    height: auto;
  }

  .mat-mdc-row {
    height: 60px;

    &.mat-mdc-row-40 {
      height: 40px;

      td {
        padding: 10px;
      }
    }
  }

  &.first-col-accent {
    .mat-mdc-cell {
      &:first-of-type {
        font-weight: 600;
        line-height: 16px;
        color: $bf-shade-100;
      }
    }
  }

  &.last-col-right-capitalized {
    .mat-mdc-cell,
    .mat-mdc-header-cell {
      &:last-of-type {
        text-align: right;
        text-transform: capitalize;
      }
    }
  }

  &.highlight-row {
    .mat-mdc-row {
      &:hover {
        &.blue-hover {
          .mat-mdc-cell {
            color: $bf-brand-primary-100;
          }
        }

        .mat-mdc-cell {
          background-color: $bf-shade-2;
        }
        .blue-hover {
          color: $bf-brand-primary-100;
        }
      }
    }
  }
}

.cs-price-table {
  .cs-price-header,
  .cs-price-cell {
    color: $bf-shade-60;
    border-collapse: collapse;
    width: 228px;
    padding: 20px 15px 15px !important;
    position: relative;
    background-color: $bf-shade-0;
    text-align: center;
    font-size: 13px;
    &:not(.mat-column-empty) {
      border: 1px solid $bf-shade-10;
    }
  }
  .cs-price-cell {
    border: 1px solid $bf-shade-10;
  }

  &-info {
    right: 10px;
    border: 1px solid $bf-brand-primary-30;
    color: $bf-brand-primary-30;
  }

  &-header-label {
    top: -15px;
    left: 22px;
  }
}

.cs-mat-table-experimental {
  width: 100%;
  border-collapse: collapse;
  background-color: transparent;

  tbody:before {
    content: '_';
    line-height: 10px;
    display: block;
    text-indent: -99999px;
  }

  .mat-mdc-header-cell,
  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    font-family: $f-family-basic;
    font-size: $bf-text-font-size-2;
    line-height: 1.5;
    color: $bf-shade-60;
    font-weight: 600;
    padding: 0 15px;

    &:first-of-type {
      padding-left: 10px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      font-weight: 600;
      color: $bf-shade-100;
    }

    &:last-of-type {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 10px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      min-width: 60px;
    }

    &.no-border {
      border-bottom: none;
    }
  }

  .mat-mdc-header-cell {
    @extend .cs-bg-shade-4, .cs-title-8, .cs-c-shade-40;

    height: 40px;
    padding: 0 15px;
    border-bottom-width: 0;
    border-bottom-style: none;
  }

  .mat-mdc-cell {
    font-weight: 400;

    &:last-of-type {
      height: 60px;
    }
  }

  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    border-bottom: none;
  }

  .mat-mdc-header-row,
  .mat-mdc-footer-row {
    height: auto;
  }

  .mat-mdc-row {
    height: 60px;
  }

  &.highlight-row {
    .mat-mdc-row:hover {
      .mat-mdc-cell {
        background-color: $bf-shade-2;
      }
    }
  }
}

.cs-cursor {
  &-pointer {
    cursor: pointer;
  }

  &-text {
    cursor: text;
  }

  &-default {
    cursor: default;
  }

  &-not-allowed {
    cursor: not-allowed;
  }
  &-grab {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }

  &-move {
    cursor: move;
  }
}

.pointer-events-none {
  pointer-events: none;
}
